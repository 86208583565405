import { ADD_NEW_PRESCRIPTION, GET_PRESCRIPTION } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class PrescriptionStore {
    isUpdatingPrescription = false;
    isLoadingPrescription = true;

    prescriptionData = {
        medications: [
            {
                medicineType: "",
                medicineName: "",
                medicineStrength: "",
                medicineDose: "",
                intakeDuration: "",
                toBeTaken: "",
                medicineIntakeTime: [],
                importantNote: "",
            },
        ],
        testReports: [],
        notes: ""
    };

    constructor({ GlobalStore }) {
        makeAutoObservable(this);
        this.GlobalStore = GlobalStore;
    }
    getPrescriptionByAppointmentId = async (appointmentId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_PRESCRIPTION}/${appointmentId}` });
                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    const [{ medications, testReports, notes }] = data;

                    this.setPrescriptionData({ medications, testReports, notes });

                    return resolve({ success: true, data });
                }

                this.setPrescriptionData();

                return resolve({ success: false, data: [{}] });
            } catch (error) {
                console.log("error", error);
                return resolve({ success: false, data: [{}] });
            }
        });
    };
    addPrescription = ({ formData, userData }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { appointmentId, doctorId, patientId } = userData;
                const { medications = [], testReports = [], notes = "" } = formData;

                const updatedMedications = medications.map(
                    ({
                        medicineType,
                        medicineName,
                        medicineStrength,
                        strengthUnit,
                        medicineDose,
                        doseUnit,
                        intakeDuration,
                        intakeUnit,
                        toBeTaken,
                        medicineIntakeTime,
                        importantNote,
                    }) => ({
                        medicineType,
                        medicineName,
                        medicineStrength: `${medicineStrength} ${strengthUnit}`,
                        medicineDose: `${medicineDose} ${doseUnit}`,
                        intakeDuration: `${intakeDuration} ${intakeUnit}`,
                        toBeTaken,
                        medicineIntakeTime,
                        importantNote,
                    }),
                );

                const updatedTestReports = testReports.map(({ report }) => report);

                const newBody = {
                    medications: updatedMedications,
                    testReports: updatedTestReports,
                    notes:notes
                };
                this.setPrescriptionData(newBody);

                const { response } = await postRequest({
                    url: `${ADD_NEW_PRESCRIPTION}/${patientId}/${doctorId}/${appointmentId}`,
                    payload: newBody,
                });
                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error && isValidArray(data)) {
                    this.getPrescriptionByAppointmentId(appointmentId);
                    return resolve({ success: true, data });
                }
                this.getPrescriptionByAppointmentId(appointmentId);

                this.setPrescriptionData();

                return resolve({ success: false, data: [{}] });
            } catch (error) {
                console.log("error", error);
                return resolve({ success: false, data: [{}] });
            }
        });
    };

    setPrescriptionData = (
        data = {
            medications: [
                {
                    medicineType: "",
                    medicineName: "",
                    medicineStrength: "",
                    medicineDose: "",
                    intakeDuration: "",
                    toBeTaken: "",
                    medicineIntakeTime: [],
                    importantNote: "",
                },
            ],
            testReports: [],
            notes: ""
        },
    ) => {
        runInAction(() => {
            this.prescriptionData = data;
        });
    };

    setIsUpdatingPrescription = (value = false) => {
        runInAction(() => {
            this.isUpdatingPrescription = value;
        });
    };

    setIsLoadingPrescription = (value = false) => {
        runInAction(() => {
            this.isLoadingPrescription = value;
        });
    };
}

export default PrescriptionStore;

// NavNotification.js

import React, { useEffect, useState } from "react";
import { Badge, List, Popover, Empty } from "antd";
import { BellOutlined, CloseOutlined, CalendarOutlined } from "@ant-design/icons";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { initializeSocket } from './socketListener';
import { getLocalStorageItems } from "utils/utilities";

const NavNotification = () => {
    const location = useLocation();
    const urlUserType = location.pathname.split("/")[1];
    const [data, setData] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const { globalStore } = useMainStore();
    const navigate = useNavigate();

    const { getNotifications, readNotification } = globalStore;
    const { user_id, userType } = getLocalStorageItems({ getAll: true, keys: ["user_id", "userType"] });

    useEffect(() => {
        const cleanup = initializeSocket(
            getNotifications,
            user_id,
            userType,
            (newData) => setData(newData)
        );

        return cleanup;
    }, []);

    const handleNotificationClick = async (item, redirect) => {
        try {
            const response = await readNotification(item._id, urlUserType);
            if (response && redirect) {
                setPopoverVisible(false);
                const url = urlUserType === "doctor" ? "/doctor/view-appointment" : "/patient/view-appointment";
                navigate(url, {
                    state: {
                        ...(urlUserType === "doctor"
                            ? { appointmentId: item.appointmentId, patientId: item.patientId }
                            : { appointment_id: item.appointmentId, patient_id: item.patientId }),
                        openModal: false,
                    }
                });
            }
        } catch (error) {
            console.error("Error reading notification:", error);
        }
    };
    const notificationList = (
        <div className="notification-container">
            <div className="nav-notification-header">
                <h4 className="mb-0">Notification</h4>
            </div>
            <div className="notification-list-wrapper">
                {data.length > 0 ? (
                    <List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                            <List.Item 
                                className={`list-clickable ${item["read_by_" + urlUserType] === true ? "read-notification" : ""}`} 
                                onClick={() => handleNotificationClick(item,true)}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <div style={{ 
                                            width: "2.5rem", 
                                            height: "2.5rem", 
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "center", 
                                            borderRadius: "50%", 
                                            backgroundColor: "#f5f5f5" 
                                        }}>
                                            <CalendarOutlined style={{ fontSize: "1.25rem" }} />
                                        </div>
                                    }
                                    title={item["admin_title"]}
                                    description={moment.utc(item.created_at).local().format("DD MMMM YYYY, dddd")}

                                />
                                <CloseOutlined 
                                    className="notification-close-icon"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent click from triggering the List.Item click event
                                        handleNotificationClick(item,false); // Function to handle notification dismissal
                                    }}
                                />
                            </List.Item>
                        )}
                    />
                ) : (
                    <Empty description="You have viewed all notifications" />
                )}
            </div>
        </div>
    );
    return (
        <Popover
            placement="bottomRight"
            title={null}
            content={notificationList}
            trigger="click"
            overlayClassName="nav-notification"
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
        >
            <div className="nav-item">
                <Badge count={data.length}>
                    <BellOutlined className="nav-icon mx-auto" type="bell" />
                </Badge>
            </div>
        </Popover>
    );
};

export default NavNotification;
import { Card, Col, Row, Tooltip } from "antd";
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL } from "constants/DateConstant";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { BiDetail } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { RiQuestionAnswerLine, RiSecurePaymentLine } from "react-icons/ri";

const StepThree = () => {
    const { patientStore, patientProfileStore } = useMainStore();

    return (
        <>
            <Observer>
                {() => {
                    const {
                        setIsAppointmentSteps,
                        isAppointmentFormData,
                        appointmentFeesDetails,
                        doctorProfileDataForPatient,
                    } = patientStore;
                    const {
                        date,
                        preDiagnosisQuestions,
                        time,
                        comment,
                        appointmentMode,
                    } = isAppointmentFormData;

                    const { patientProfileData } = patientProfileStore;
                    const [{ name }] = patientProfileData;
                    const [{ online, offline }] = appointmentFeesDetails;
                    const [startTime, endTime] = time.split("-");
                    const [{ doctorProfile, speciality, degrees, clinicInfo, fees }] = doctorProfileDataForPatient;

                    return (
                        <>
                            <Row className="mt-3" gutter={[16, 16]}>
                                <Col span={16}>
                                    <Card className="w-100">
                                        <Row className="border-bottom pb-2 ">
                                            <Col className="d-flex align-items-center">
                                                <BiDetail color="#455560" size={28} />
                                                <span className="ml-2 font-weight-bolder font-size-xl text-gray">
                                                    {t("Appointment Details")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2" gutter={[32, 16]}>
                                            <Col span={24} xl={24} xxl={12}>
                                                <span className="d-block font-weight-bold mb-1">{t("Doctor Details")}</span>
                                                <h4 className="text-gray-light font-size-md text-black mb-0">
                                                    {doctorProfile?.name}
                                                </h4>
                                                <p className="mb-0">{clinicInfo?.address}</p>
                                            </Col>
                                            <Col span={12}>
                                                <span className="d-block font-weight-bold mb-1">{t("Patient Name")}</span>
                                                <h4 className="text-gray-light font-size-md text-black mb-0">{name}</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col span={18}>
                                                <span className="d-block font-weight-bold ">{t("Date & Time")}</span>
                                                <p className="mb-0 d-inline-block">
                                                    {moment(date, "DD-MM-YYYY").format(
                                                        DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL,
                                                    )}
                                                    , {moment.utc(startTime, "hh:mm A").local().format("hh:mm A")}
                                                </p>{" "}
                                                <Tooltip title={t("Change slot")}>
                                                    <FaRegEdit
                                                        size={18}
                                                        color="#3E79F7"
                                                        className="ml-1 p-0 cursor_pointer"
                                                        onClick={() =>
                                                            setIsAppointmentSteps({ steps: 0, isSuccess: true })
                                                        }
                                                    />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card className="w-100">
                                        <Row className="border-bottom pb-3 ">
                                            <Col className="d-flex align-items-center">
                                                <RiSecurePaymentLine color="#FF00002" size={25} />
                                                <span className="font-weight-bold font-size-md ml-1">
                                                    {t("Payment Details")}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="mt-2">
                                                {appointmentMode == "online" && (
                                                    <Row justify="space-between" align="middle">
                                                        <Col span={12}>
                                                            <p className="mb-0">{t("Service Fees")}</p>
                                                        </Col>
                                                        <p className="mb-0">${online?.new_case_fees}</p>
                                                        <Col span={12}></Col>
                                                    </Row>
                                                )}
                                                {appointmentMode == "offline" && (
                                                    <Row justify="space-between" align="middle">
                                                        <Col span={12}>
                                                            <p className="mb-0">{t("Service Fees")}</p>
                                                        </Col>
                                                        <p className="mb-0">${offline?.new_case_fees}</p>
                                                        <Col span={12}></Col>
                                                    </Row>
                                                )}
                                                <Row justify="space-between" align="middle">
                                                    <Col span={12}>
                                                        <p className="mb-0">{t("Any Extra Charges")}</p>
                                                    </Col>
                                                    <p className="mb-0">$ 0</p>
                                                    <Col span={12}></Col>
                                                </Row>
                                                <Row justify="space-between" align="middle" className="mt-3">
                                                    <Col span={12}>
                                                        <p className="font-weight-bold ">{t("Total Amount")}</p>
                                                    </Col>
                                                    {appointmentMode == "online" && (
                                                        <p className="font-weight-bold font-size-md">
                                                            ${online.new_case_fees}
                                                        </p>
                                                    )}
                                                    {appointmentMode == "offline" && (
                                                        <p className="font-weight-bold font-size-md">
                                                            ${offline?.new_case_fees}
                                                        </p>
                                                    )}
                                                    <Col span={12}></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card className="w-100">
                                        <Row className="pb-3 border-bottom ">
                                            <Col className="d-flex align-items-center">
                                                <RiQuestionAnswerLine color="#FF00002" size={24} />
                                                <span className="ml-2 font-weight-bolder font-size-md ">{t("Questions")}</span>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="mt-1"
                                            style={{
                                                maxHeight: "250px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            {preDiagnosisQuestions.map(({ question, answer }, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <Col span={24} className="d-flex align-items-center">
                                                            <span size={14} className="font-weight-bold">{`Q ${
                                                                index + 1
                                                            }.`}</span>
                                                            <p className="d-inline ml-2 mb-0 font-weight-bold">
                                                                {question}
                                                            </p>
                                                        </Col>
                                                        <Col span={24} className="d-flex align-items-baseline">
                                                            <div className="">
                                                                <span size={14} className="font-weight-bold">
                                                                    {t("Answer")}.
                                                                </span>
                                                            </div>{" "}
                                                            <p className="d-inline ml-2 mb-0 text-capitalize align-middle mb-1">
                                                                {answer}
                                                            </p>
                                                        </Col>
                                                    </Fragment>
                                                );
                                            })}
                                            <>
                                                <Col span={24} className="d-flex align-items-center">
                                                    <span
                                                        size={14}
                                                        className="font-weight-bold mt-3 "
                                                    >{t("Symptoms")}:</span>
                                                </Col>
                                                <Col span={24} className="d-flex align-items-baseline">
                                                    <p className="d-inline mb-0  align-middle mb-1">
                                                        {comment}
                                                    </p>
                                                </Col>
                                            </>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            </Observer>
        </>
    );
};

export default StepThree;

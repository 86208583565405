import { InboxOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { getLocalStorageItems, isValidArray } from "utils/utilities";
const UploadLabReports = ({ type }) => {
    const { patientStore } = useMainStore();
    const { Dragger } = Upload;
    const [uplaodForm] = useForm();

    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

    let labFormData = new FormData();
    const onFinish = (value) => {
        const {
            updateLabReports,
            setIsLoadingForUpdateLabReports,
            getLabReports,
            setIsOpenModalForAddLab,
            setAppointmentSelectDocumentData,
            appointmentSelectDocumentData,
        } = patientStore;
        const { reportName, reportDate, patientName, fileDocument } = value;
        const { newDocument } = appointmentSelectDocumentData;
        const { file, fileList } = fileDocument;
        setIsLoadingForUpdateLabReports(true);

        if (file.status !== "uploading") {
            const formData = new FormData();
            const documentData = fileList.map((data) => {
                labFormData.append("file", data.originFileObj);
                return data.originFileObj;
            });
        }
        labFormData.append("reportName", reportName);
        labFormData.append("reportDate", moment(reportDate).toISOString());
        labFormData.append("patientName", patientName);
        updateLabReports(patientId, labFormData)
            .then(({ data, success }) => {
                if (success) {
                    getLabReports(patientId);
                    setIsLoadingForUpdateLabReports(false);
                    setIsOpenModalForAddLab();
                    uplaodForm.resetFields();
                }
                if (type === "book-appointment" && success && isValidArray(data)) {
                    const [{ report_id }] = data;
                    setAppointmentSelectDocumentData({
                        ...appointmentSelectDocumentData,
                        newDocument: [report_id, ...newDocument],
                    });
                }
            })
            .finally(() => {
                setIsLoadingForUpdateLabReports(false);
            });
    };

    const fileUploadSettings = {
        customRequest: ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("Ok");
            });
        },
        name: "file",
        multiple: false,
        beforeUpload: true,
        maxCount: 1,
        // onChange: async ({ file, fileList }) => {
        //     console.log("🚀 ~ onChange: ~ fileList:", fileList);
        // },
        onRemove: () => {
            uplaodForm.setFieldsValue({ fileDocument: {} });
            labFormData.delete("file");
        },
    };

    const validateUploadFile = (_, value) => {
        const { file, fileList } = value;
        if (!isValidArray(fileList)) {
            return Promise.reject(t("Please select a file to upload"));
        } else {
            return Promise.resolve();
        }
    };
    const disabledDate = (current) => {
        const currentDate = new Date();
        return current && current.year() > currentDate.getFullYear() || current > currentDate.setHours(24,0, 0, 0);
    };
    return (
        <Observer>
            {() => {
                const {
                    setIsOpenModalForAddLab,
                    isOpenModalForAddLab,
                    isLoadingForUpdateLabReports,
                    allLabReports,
                    isLoadingForAllLabReports,
                } = patientStore;
                {
                    /* if (isLoadingForAllLabReports) {
                    return <Loading />;
                } */
                }
                {
                    /* const [{ reports = [] } = {}] = allLabReports; */
                }
                return (
                    <>
                        <Modal
                            title={t("Upload Test Reports")}
                            // centered
                            closable={() => setIsOpenModalForAddLab(false)}
                            open={isOpenModalForAddLab}
                            footer={false}
                            // onOk={() => setOpen(false)}
                            onCancel={() => setIsOpenModalForAddLab(false)}
                            width={700}
                        >
                            <Form
                                name="dynamic_form_nest_item"
                                onFinish={onFinish}
                                autoComplete="off"
                                form={uplaodForm}
                            >
                                <Row gutter={[16, 8]} justify="center">
                                    <Col span={24}>
                                        <Form.Item
                                            name={"fileDocument"}
                                            rules={[
                                                // {
                                                //     required: true,
                                                //     message: "Please upload document",
                                                // },
                                                {
                                                    validator: validateUploadFile,
                                                    message: t("Please upload document"),
                                                },
                                            ]}
                                        >
                                            <Dragger {...fileUploadSettings}>
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="">{t("Click or drag file to this area to upload")}</p>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="reportName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("Please enter report name"),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t("Report Title")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            name="patientName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("Please enter patient name"),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t("Patient Name")} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name={"reportDate"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("Please select date"),
                                                },
                                            ]}
                                        >
                                            <DatePicker className="w-100" disabledDate={disabledDate} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="end" className="mt-3">
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                loading={isLoadingForUpdateLabReports}
                                                type="primary"
                                                htmlType="submit"
                                                // loading={loadingForUploadDocumentForPrescription}
                                            >
                                                {t("Submit")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </>
                );
            }}
        </Observer>
    );
};

export default UploadLabReports;

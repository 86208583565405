import { Button, DatePicker, Form, Space, Spin, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";
import PastAppointment from "./PastAppointment";
import UpcomingAppointment from "./UpcomingAppointment";
import { t } from "i18next";

const PatientAppointment = () => {
    const { patientStore } = useMainStore();
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [form] = useForm();

    const items = [
        {
            key: "upcoming",
            label: t("Upcoming"),
            children: <UpcomingAppointment patientId={patientId} />,
        },
        {
            key: "past",
            label: t("Past"),
            children: <PastAppointment patientId={patientId} />,
        },
    ];

    const typeVal = searchParams.get("type");

    useEffect(() => {
        const { getPatientAppointmenList, setIsLoadingForAppointmentList } = patientStore;

        setIsLoadingForAppointmentList(true);
        getPatientAppointmenList(patientId, typeVal ? typeVal : "upcoming")
            .then(({ success, data }) => {
                if (success && isValidArray(data)) {
                    setIsLoadingForAppointmentList();
                }
            })
            .finally(() => {
                setIsLoadingForAppointmentList();
            });
    }, [typeVal]);


    const onDateChange = (date, dateString) => {
        let selectedDate;
        if (date) {
            selectedDate = moment(date).toISOString();
        }

        const { getPatientAppointmenList, setIsLoadingForAppointmentList } = patientStore;
        setIsLoadingForAppointmentList(true);
        if (typeVal) {
            getPatientAppointmenList(patientId, typeVal, selectedDate)
                .then(({ success, data }) => {
                    if (success && isValidArray(data)) {
                        setIsLoadingForAppointmentList();
                    }
                })
                .finally(() => {
                    setIsLoadingForAppointmentList();
                });
        } else
            getPatientAppointmenList(patientId, "upcoming", selectedDate)
                .then(({ success, data }) => {
                    if (success && isValidArray(data)) {
                        setIsLoadingForAppointmentList();
                    }
                })
                .finally(() => {
                    setIsLoadingForAppointmentList();
                });
    };

    const onChange = (key) => {
        form.resetFields();
        if (key == "upcoming") {
            navigate("/patient/appointments?type=upcoming");
        }
        if (key == "past") {
            navigate("/patient/appointments?type=past");
        }
    };


    const disabledDate = (current) => {
        const currentDate = new Date();
        if (typeVal == "upcoming") {
            return current.year() > currentDate.getFullYear() || current < currentDate.setHours(0, 0, 0, 0);
        }
        if (typeVal == "past") {
            return current.year() > currentDate.getFullYear() || current > currentDate.setHours(0, 0, 0, 0);
        }
        return current.year() > currentDate.getFullYear() || current < currentDate.setHours(0, 0, 0, 0);
    };
    return (
        <Observer>
            {() => {
                const { listOfAppointment, isLoadingForAppointmentList, listOfAppointmentPast, listOfAppointmentUpcoming, defaultDate, setDefaultDate } = patientStore;
                return (
                    <>
                        <Spin spinning={(!isValidArray(listOfAppointmentPast) || !isValidArray(listOfAppointmentUpcoming)) && isLoadingForAppointmentList}>
                            <div className="appointments_wrapper">
                                <div className="appointments_heading d-flex justify-content-between ">
                                    <div>
                                        <h2>{t("Appointments")}</h2>
                                    </div>
                                    <div className="d-flex">
                                        {/* <Space> */}
                                        <Button type="primary" className="mr-3" >
                                            <Link to={"/patient/find-doctor"}>{t("Book Appointment")}</Link>
                                        </Button>
                                        <Form form={form}>
                                            <Form.Item name="appointment_date">
                                                <DatePicker onChange={onDateChange} disabledDate={disabledDate} format={DATE_FORMAT_DD_MM_YYYY} />
                                            </Form.Item>
                                        </Form>
                                        {/* </Space> */}

                                    </div>
                                </div>
                                <Tabs
                                    defaultActiveKey={searchParams.get("type")}
                                    items={items}
                                    onChange={onChange}
                                    className="upcoming_tab"
                                />
                            </div>
                        </Spin>
                    </>
                );
            }}
        </Observer>
    );
};

export default PatientAppointment;

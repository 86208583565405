import { Button, Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FaPrescription } from "react-icons/fa";

import Loading from "components/shared-components/Loading";
import JitsiMeet from "components/shared-components/jitsiMeet/JitsiMeet";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppointmentComplitionModal } from "utils/hooks/useAppoitmnetComplitionModal";
import { getLocalStorageItems } from "utils/utilities";
import AddPrescriptionForm from "./AddPrescriptionForm";
import { t } from "i18next";


const VideoConsultation = ({ direction }) => {
    const [open, setOpen] = useState(false);
    const { showConfirm, confirmationModal } = useAppointmentComplitionModal();

    const { videoConferenceStore, globalStore } = useMainStore();

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        const { setRoomName, getAppointmentDetailsById, setIsLoadingAppointment, getUserDetailsById, setUserDetails } =
            videoConferenceStore;
        const { appointmentId, userType } = state;
        const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });
        getAppointmentDetailsById(appointmentId)
            .then(({ success }) => {
                if (success) setRoomName(appointmentId);
            })
            .then(() => getUserDetailsById(user_id))
            .catch(({ message }) => console.log(message))
            .finally(() => setIsLoadingAppointment());

        return () => {
            setIsLoadingAppointment(true);
            setUserDetails();
        };
    }, [state]);


    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleEndSession = async () => {
        const { userType, appointmentId, patientId, doctorId } = state;
        const { jitsiAPI } = globalStore;
        const { setIsUpdatingCompletionStatus, } = videoConferenceStore;

        setIsUpdatingCompletionStatus(true);

        if (userType === 0) {
            confirmationModal()
                .then(async ({ success }) => {
                    if (success) {
                        jitsiAPI.executeCommand("hangup");
                        navigate(`/doctor/view-appointment`, {
                            state: { appointmentId: appointmentId, patientId: patientId, openModal: true },
                            replace: true,
                        });
                    }
                })
                .finally(() => setIsUpdatingCompletionStatus());
        }
        if (userType === 1) {
            confirmationModal()
                .then(async ({ success }) => {
                    if (success) {
                        navigate("/patient/view-appointment", {
                            state: { appointment_id: appointmentId, patient_id: patientId },
                            replace: true,
                        });
                    }
                })
                .finally(() => setIsUpdatingCompletionStatus());
        }
    };

    
    return (
        <Observer>
            {() => {
                const { isLoadingAppointment, userDetails, isUpdatingCompletionStatus } = videoConferenceStore;
                const [{ name }] = userDetails;
                const { appointmentId, userType } = state;

                if (isLoadingAppointment) {
                    return <Loading />;
                }

                return (
                    <div className="videocall_wrapper">
                        <div className="video_header">
                            <Row align="middle" justify="space-between">
                                <Col>
                                    <div className="d-flex align-items-center">
                                        <img src="img/video-call.png" className="pl-3" alt="" />
                                        <h2 className="text-white ml-3">{name}</h2>
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary mr-3"
                                        loading={isUpdatingCompletionStatus}
                                        disabled={isUpdatingCompletionStatus}
                                        onClick={handleEndSession}
                                    >
                                        {t("End Session")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="video_screen">
                            <Row>
                                <Col span={23}>
                                    <div className="p-3">
                                        <JitsiMeet />
                                    </div>
                                </Col>
                                {userType === 0 && (
                                    <Col span={1}>
                                        <div className="text-center screen_sidebar pb-3">
                                            <Tooltip
                                                title={<p className="tooltip__add_prescription">Add Prescription</p>}
                                                placement="left"
                                            >
                                                <div className="nav-item" onClick={showDrawer}>
                                                    <FaPrescription className="nav-icon mr-0" />
                                                </div>
                                            </Tooltip>
                                            <AddPrescriptionForm open={open} onClose={onClose} direction={direction} />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default VideoConsultation;

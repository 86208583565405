import { Card, Col, Row, Table, Tag } from "antd";
import GoBack from "components/shared-components/GoBack";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import { toUpper } from "lodash";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, getAgeFromDOB, getLocalStorageItems, isValidArray } from "utils/utilities";

const PrescriptionDetails = () => {
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

    const { state } = useLocation();
    const { prescriptionId } = state;
    const { patientPrescriptionStore } = useMainStore();

    useEffect(() => {
        const { getPatientPrescriptionDetails, setIsLoadingPatientPrescriptionDetails, setPatientPrescriptionDetails } =
            patientPrescriptionStore;
        setIsLoadingPatientPrescriptionDetails(true);
        getPatientPrescriptionDetails(patientId, prescriptionId).finally(() =>
            setIsLoadingPatientPrescriptionDetails(false),
        );

        return () => {
            setIsLoadingPatientPrescriptionDetails(true);
            setPatientPrescriptionDetails();
        };
    }, [prescriptionId]);

    const columns = [
        {
            title: t("Medicine Type"),
            dataIndex: "medicineType",
            key: "medicineType",
            render: (_, { medicineType }) => {
                return capitalizeFirstLetter(medicineType);
            },
        },
        {
            title: t("Medicine Name"),
            dataIndex: "medicineName",
            key: "medicineName",
            render: (_, { medicineName }) => {
                return capitalizeFirstLetter(medicineName);
            },
        },
        {
            title: t("Strength"),
            dataIndex: "medicineStrength",
            key: "medicineStrength",
            render: (_, { medicineStrength }) => {
                return capitalizeFirstLetter(medicineStrength);
            },
        },
        {
            title: t("Medicine Dose"),
            dataIndex: "medicineDose",
            key: "medicineDose",
            render: (_, { medicineDose }) => {
                return capitalizeFirstLetter(medicineDose);
            },
        },
        {
            title: t("Intake Duration"),
            dataIndex: "intakeDuration",
            key: "intakeDuration",
            render: (_, { intakeDuration }) => {
                return capitalizeFirstLetter(intakeDuration);
            },
        },
        {
            title: t("To be Taken"),
            dataIndex: "toBeTaken",
            key: "toBeTaken",
            render: (_, { toBeTaken }) => {
                if (toBeTaken === "after_food") return "After Food";
                if (toBeTaken === "before_food") return "Before Food";
            },
        },
        {
            title: t("Medicine Time"),
            dataIndex: "time",
            key: "time",
            render: (_, { medicineIntakeTime }) => {
                return medicineIntakeTime.map((time) => (
                    <Tag
                        color={time == t("morning") ? "blue" : time == t("noon") ? "yellow" : "orange"}
                        className="text-capitalize"
                    >
                        {capitalizeFirstLetter(time)}
                    </Tag>
                ));
            },
        },
        {
            title: t("Note"),
            dataIndex: "importantNote",
            key: "importantNote",
        },
    ];

    return (
        <>
            <Observer>
                {() => {
                    const { patientPrescriptionDetails, isLoadingPatientPrescriptionDetails } =
                        patientPrescriptionStore;

                    if (isLoadingPatientPrescriptionDetails && !isValidArray(patientPrescriptionDetails)) {
                        return <Loading />;
                    }

                    return (
                        <>
                            <div className="mb-2">
                                <GoBack />
                            </div>

                            {patientPrescriptionDetails.map(
                                ({ prescription_id, doctor, appointment, medications, patient, testReports,notes="", unique_id="" }) => {
                                    return (
                                        <Card key={prescription_id}>
                                            <div className="prescription_details">
                                                <div className="d-flex justify-content-between align-items-start pb-3">
                                                    <div>
                                                        <h1 className="mb-1">{t("Details of Prescription")}</h1>{" "}
                                                        <h4 className="font-weight-bold text-body">
                                                            {t("Prescription")} # :
                                                            <span className="font-weight-normal">{unique_id}</span>
                                                        </h4>
                                                    </div>
                                                    <div>
                                                        <h2>{doctor.name}</h2>
                                                        <h4>Mo.: {doctor.mobile_no}</h4>
                                                        {/* <h4 className="font-weight-normal">Reg. No. MMC/2016/08/3524</h4> */}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="py-3 patient_detail">
                                                    <div>
                                                        <Row>
                                                            <Col xl={3} lg={6} md={7} span={9}>
                                                                <img
                                                                    src="/img/patient.png"
                                                                    width={50}
                                                                    height={50}
                                                                    alt=""
                                                                />
                                                            </Col>
                                                            <Col xl={21} lg={18} md={17} span={15}>
                                                                <Row>
                                                                    <Col xl={8} lg={12} span={12}>
                                                                        <h4>{t("Patient Name")}</h4>
                                                                        <p>{capitalizeFirstLetter(patient.name)}</p>
                                                                    </Col>
                                                                    <Col xl={8} lg={12} span={12}>
                                                                        <h4>{t("Age")}</h4>
                                                                        <p>{getAgeFromDOB(patient.dob)}</p>
                                                                    </Col>
                                                                    <Col xl={8} lg={12} span={12}>
                                                                        <h4>{t("Gender")}</h4>
                                                                        <p>{capitalizeFirstLetter(patient.gender)}</p>
                                                                    </Col>
                                                                    <Col xl={8} lg={12} span={12}>
                                                                        <h4>{t("Mobile")}</h4>
                                                                        <p>{patient.mobile_no}</p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                
                                                {notes!="" && (
                                                    <>
                                                        <hr />
                                                        <div className="py-3 notes">
                                                            <Row>
                                                                <Col span={24}>
                                                                    <h4>{t("Notes")}</h4>
                                                                </Col>
                                                                <Col span={24} className="pt-3">
                                                                {notes}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>
                                                )}
                                                
                                                {isValidArray(testReports) && (                                                                                                                                                                                                                                                                           
                                                    <>
                                                        <hr />
                                                        <div className="py-3 patient_detail">
                                                            <Row>
                                                                <Col span={24}>
                                                                    <h4>{t("Lab Reports")}</h4>
                                                                </Col>
                                                                <Col span={24} className="pt-3">
                                                                    {testReports.map((lab, ind) => {
                                                                        return (
                                                                            <Tag key={ind} color="default">
                                                                                {toUpper(lab)}
                                                                            </Tag>
                                                                        );
                                                                    })}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </>
                                                )}
                                                <hr />
                                                <div className="pt-3 patient_detail">
                                                    <h4>{t("Medications Details")}</h4>
                                                    <Table
                                                        columns={columns}
                                                        dataSource={medications.map((item, ind) => ({
                                                            ...item,
                                                            key: ind,
                                                        }))}
                                                        className="pt-3"
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    );
                                },
                            )}
                        </>
                    );
                }}
            </Observer>
        </>
    );
};

export default PrescriptionDetails;

import { DatePicker, Form, Spin, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT_DD_MM_YYYY } from "constants/DateConstant";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getLocalStorageItems, isValidArray } from "utils/utilities";
import PastAppointments from "./pastAppointments";
import UpcomingAppointments from "./upcomingAppointments";
import { t } from "i18next";

const DoctorAppointments = () => {
    const { doctorStore } = useMainStore();
    const [form] = useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { defaultSelectedDate } = doctorStore;
    const typeVal = searchParams.get("type");
    const { user_id: doctorId } = getLocalStorageItems({ get: true, key: "user_id" });

    useEffect(() => {
        const { getPatientAppointmenListByDoctor, setIsLoadingOfListOfAppointmentByDoctor, defaultSelectedDate } =
            doctorStore;
        setIsLoadingOfListOfAppointmentByDoctor(true);
        getPatientAppointmenListByDoctor(doctorId, typeVal ? typeVal : "upcoming").finally(() => {
            setIsLoadingOfListOfAppointmentByDoctor();
        });
    }, [typeVal]);
    const onDateChange = (date, dateString) => {

        let selectedDate;
        if (date) {
            selectedDate = moment(date).toISOString();
        }
        const { getPatientAppointmenListByDoctor, setIsLoadingOfListOfAppointmentByDoctor, defaultSelectedDate } =
            doctorStore;
        setIsLoadingOfListOfAppointmentByDoctor(true);
        if (typeVal) {
            getPatientAppointmenListByDoctor(doctorId, typeVal, selectedDate)
                .then(({ success, data }) => {
                    if (success && isValidArray(data)) {
                        setIsLoadingOfListOfAppointmentByDoctor();
                    }
                })
                .finally(() => {
                    setIsLoadingOfListOfAppointmentByDoctor();
                });
        } else
            getPatientAppointmenListByDoctor(doctorId, "upcoming", selectedDate)
                .then(({ success, data }) => {
                    if (success && isValidArray(data)) {
                        setIsLoadingOfListOfAppointmentByDoctor();
                    }
                })
                .finally(() => {
                    setIsLoadingOfListOfAppointmentByDoctor();
                });
    };
    const disabledDate = (current) => {
        const currentDate = new Date();
        if (typeVal == "upcoming") {
            return current.year() > currentDate.getFullYear() || current < currentDate.setHours(0, 0, 0, 0);
        }
        if (typeVal == "past") {
            return current.year() > currentDate.getFullYear() || current > currentDate.setHours(0, 0, 0, 0);
        }
        return current.year() > currentDate.getFullYear() || current < currentDate.setHours(0, 0, 0, 0);
    };

    return (
        <Observer>
            {() => {
                const {
                    listOfAppointmentByDoctor,
                    isLoadingOfListOfAppointmentByDoctor,
                    defaultSelectedDate,
                    setDefaultSelectedDate,
                    upcomingAppointmentsList,
                    pastAppointmentsList
                } = doctorStore;

                return (
                    <>
                        <Spin spinning={(!isValidArray(pastAppointmentsList) || !isValidArray(upcomingAppointmentsList)) && isLoadingOfListOfAppointmentByDoctor}>
                            <div className="appointments_wrapper">
                                <div className="appointments_heading d-flex justify-content-between">
                                    <h2>{t("Appointments")}</h2>
                                    <Form form={form}>
                                        <Form.Item name="appointment_date">
                                            <DatePicker onChange={onDateChange} disabledDate={disabledDate} format={DATE_FORMAT_DD_MM_YYYY} />
                                        </Form.Item>
                                    </Form>
                                </div>
                                <Tabs
                                    className="upcoming_tab"
                                    defaultActiveKey={typeVal}
                                    onChange={(value) => {
                                        form.resetFields();
                                        if (value === "past") navigate("/doctor/appointments?type=past");
                                        if (value === "upcoming") navigate("/doctor/appointments?type=upcoming");
                                    }}
                                    items={[
                                        {
                                            key: "upcoming",
                                            label: t("Upcoming"),
                                            children: <UpcomingAppointments />,
                                        },
                                        {
                                            key: "past",
                                            label: t("Past"),
                                            children: <PastAppointments />,
                                        },
                                    ]}
                                />
                            </div>
                        </Spin >
                    </>
                );
            }}
        </Observer >
    );
};

export default DoctorAppointments;

import { StoreProvider, mainStore } from "mobxStore/mainStore";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "routes";
import "../src/style/style.css";
import "./assets/css/index.css";
import { THEME_CONFIG } from "./configs/AppConfig";
import history from "./history";
import "./lang";
import store from "./store";

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <StoreProvider value={mainStore}>
                    <BrowserRouter history={history}>
                        <ThemeSwitcherProvider
                            themeMap={themes}
                            defaultTheme={THEME_CONFIG.currentTheme}
                            insertionPoint="styles-insertion-point"
                        >
                            <AppRoutes />
                        </ThemeSwitcherProvider>
                    </BrowserRouter>
                </StoreProvider>
            </Provider>
        </div>
    );
}

export default App;

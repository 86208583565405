import { DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Select, Upload } from "antd";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import { toUpper } from "lodash";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";

const UploadTestReportsModal = ({ patientId, appointmentId }) => {
    const { patientStore } = useMainStore();

    const fileUploadSettings = {
        customRequest: ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("Ok");
            });
        },
        name: "file",
        multiple: false,
        beforeUpload: false,
        maxCount: 1,
    };

    const onFinish = async (values) => {
        const {
            uploadTestReports,
            getUrlForUploadedDocument,
            setIsOpenModalForPriscriptionDocument,
            setLoadingForUploadDocumentForPrescription,
            getUploadTestReportsForPrescriptions,
        } = patientStore;

        setLoadingForUploadDocumentForPrescription(true);
        
        const { reports } = values;
        const fileData = await reports.map(async (formVal) => {
            const { document_list, document_name } = formVal;
            const { fileList } = document_list;
            const [{ originFileObj }] = fileList;
            const formData = new FormData();
            formData.append("file", originFileObj);
            const { data } = await getUrlForUploadedDocument(patientId, formData);
            return {
                testName: document_name,
                report: data[0],
            };
        });
        const data = await Promise.all(fileData);
        uploadTestReports(patientId, appointmentId, { testReports: data }).then(() => {
            setLoadingForUploadDocumentForPrescription();
            getUploadTestReportsForPrescriptions(patientId, appointmentId);
            setIsOpenModalForPriscriptionDocument();
        });
    };
    return (
        <Observer>
            {() => {
                const {
                    patientAppointmentDetails,
                    isLoadingForPatientAppointmentDetails,
                    isOpenModalForPriscriptionDocument,
                    setIsOpenModalForPriscriptionDocument,
                    loadingForUploadDocumentForPrescription,
                } = patientStore;

                const [{ prescription }] = patientAppointmentDetails;

                if (isLoadingForPatientAppointmentDetails) {
                    return <Loading />;
                }

                const [{ medications = [], testReports: medicationTestReports = [] } = {}] = prescription;

                return (
                    <>
                        <Modal
                            title={t("Upload Test Reports")}
                            closable={() => setIsOpenModalForPriscriptionDocument()}
                            open={isOpenModalForPriscriptionDocument}
                            footer={false}
                            onCancel={() => setIsOpenModalForPriscriptionDocument(false)}
                            width={700}
                        >
                            <Form
                                name="dynamic_form_nest_item"
                                onFinish={onFinish}
                                style={{
                                    maxWidth: 600,
                                }}
                                autoComplete="off"
                                initialValues={{
                                    reports: [{}],
                                }}
                            >
                                <Form.List name="reports">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }, ind) => (
                                                <Row gutter={[16, 16]} justify="space-between" align="">
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "document_name"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("Please select report"),
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                placeholder={t("Select a Document")}
                                                                options={medicationTestReports.map((data) => {
                                                                    return {
                                                                        label: toUpper(data),
                                                                        value: data,
                                                                    };
                                                                })}
                                                            ></Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, "document_list"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("Please upload document"),
                                                                },
                                                            ]}
                                                        >
                                                            <Upload {...fileUploadSettings}>
                                                                <Button icon={<UploadOutlined />}>
                                                                    {t("Click to Upload")}
                                                                </Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        span={2}
                                                        className="d-flex justify-content-end align-items-top"
                                                    >
                                                        {ind !== 0 && (
                                                            <Button
                                                                icon={<DeleteOutlined />}
                                                                danger
                                                                onClick={() => remove(name)}
                                                            ></Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row justify="center">
                                                <Col>
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            block
                                                            icon={<PlusOutlined />}
                                                        >
                                                            {t("Add Document")}
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Form.List>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loadingForUploadDocumentForPrescription}
                                    >
                                        {t("Submit")}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </>
                );
            }}
        </Observer>
    );
};

export default UploadTestReportsModal;

import { Button, Col, Form, Row, Space, Steps, Upload } from "antd";
import GoBack from "components/shared-components/GoBack";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ERROR, getLocalStorageItems, isValidObject, sendNotification } from "utils/utilities";
import PaymentModal from "./PaymentModal";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { t } from "i18next";

const { Step } = Steps;
const { Dragger } = Upload;

const BookAppointment = () => {
    const { patientStore } = useMainStore();
    const { setIsSelectedDateByCalender } = patientStore;
    const [form] = Form.useForm();
    const [slotForm] = Form.useForm();
    const [commentForm] = Form.useForm();

    const location = useLocation();
    const { doctorId, appointmentType } = location.state;
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });

    const currentDate = moment();
    setIsSelectedDateByCalender(currentDate);

    useEffect(() => {
        const {
            getDoctorsAvailableSlots,
            setIsLoadingDoctorAvailableSlots,
            setIsAppointmentIdForActive,
            setIsAppointmentSteps,
            setIsLoadingDoctorsProfile,
            getDoctorsDetailForPatient,
            setIsSelectedDateByCalender
        } = patientStore;

        const selectedDate = { date: currentDate.format("DD-MM-YYYY") };

        // fetch doctor details start **************
        setIsLoadingDoctorsProfile(true);
        getDoctorsDetailForPatient(doctorId).finally(() => setIsLoadingDoctorsProfile());
        // fetch doctor details end **************

        setIsAppointmentSteps(); // for always initial step

        // fetch available slots start
        setIsAppointmentIdForActive(null);
        setIsLoadingDoctorAvailableSlots(true);
        getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
    }, []);


    // next steps functions
    const onNexStep = () => {
        const {
            getDoctorDiagnosisQueestion,
            isAppointmentFormData,
            setIsAppointmentFormData,
            isSelectedDateByCalender,
            setIsAppointmentSteps,
            isAppointmentSteps,
            getAppointmentFeesDetails,
            getLabReports,
            setIsLoadingForAllLabReports,
            appointmentSelectDocumentData,
            getPaymentOptionList,
        } = patientStore;

        const { selectDocument, newDocument } = appointmentSelectDocumentData;
        const { steps, isSuccess } = isAppointmentSteps;
        const { testReports = [] } = isAppointmentFormData;

        if (steps == 0) {
            slotForm
                .validateFields()
                .then((data) => {
                    const { time, appointmentMode } = data;
                    const [startTime, endTime] = time.split("-")
                    const startTimeWithDate = moment(`${isSelectedDateByCalender.format("DD-MM-YYYY")} ${moment.utc(startTime, "hh:mm A").local().format("hh:mm A")}`, "DD-MM-YYYY hh:mm A").toISOString();
                    const endTimeWithDate = moment(`${isSelectedDateByCalender.format("DD-MM-YYYY")} ${moment.utc(endTime, "hh:mm A").local().format("hh:mm A")}`, "DD-MM-YYYY hh:mm A").toISOString();


                    setIsAppointmentFormData({
                        ...isAppointmentFormData,
                        ...data,
                        startTime: startTimeWithDate, // This os in ISO format
                        endTime: endTimeWithDate,
                        date: isSelectedDateByCalender.format("DD-MM-YYYY"),
                        preDiagnosisQuestions: [],
                        testReports: [...testReports],
                        appointmentType: appointmentType,
                        comment: "",
                    });
                    if (isValidObject(data)) {
                        setIsLoadingForAllLabReports(true);
                        getLabReports(patientId).finally(() => {
                            setIsLoadingForAllLabReports();
                        });
                        setIsAppointmentSteps({ isSuccess: false, steps: 1 });
                    } else {
                        sendNotification({
                            message: t("Please select slot and appointment mode on another date"),
                            type: ERROR,
                        });
                    }
                    getDoctorDiagnosisQueestion(doctorId);
                })
                .catch((errorInfo) => {
                    sendNotification({ message: t("Please select slot and appointment mode"), type: ERROR });
                });
        } else if (isSuccess == false && steps == 0) {
            sendNotification({ message: "Please select time slot", type: ERROR });
        }

        if (steps == 1) {
            form.validateFields()
                .then((data) => {
                    const arrayOfObjects = Object.entries(data).map(([key, value]) => ({
                        question: key,
                        answer: value,
                    }));
                    setIsAppointmentFormData({
                        ...isAppointmentFormData,
                        preDiagnosisQuestions: arrayOfObjects,
                        ...commentForm.getFieldValue(),
                        testReports: [...selectDocument, ...newDocument],
                    });
                    getAppointmentFeesDetails(doctorId).then(() => {
                        setIsAppointmentSteps({ ...isAppointmentSteps, steps: 2 });
                    });
                    getPaymentOptionList(doctorId);
                })
                .catch((errorInfo) => {
                    console.log("🚀 ~ onNexStep ~ errorInfo:", errorInfo);
                });
        }
    };


    // book appointment step-by-step
    const steps = [
        {
            title: t("Select Slot Time"),
            content: <StepOne slotForm={slotForm} doctorId={doctorId} />,
        },

        {
            title: t("Diagnosis Questions"),
            content: <StepTwo commentForm={commentForm} form={form} />,
        },

        {
            title: t("Confirm Booking"),
            content: <StepThree />,
        },
    ];


    return (
        <Observer>
            {() => {
                const { setIsAppointmentSteps, isAppointmentSteps, isNextButtonEnable, setIsPaymentOptionModal } =
                    patientStore;


                return (
                    <>
                        <Row align="middle">
                            <Col>
                                <GoBack />
                            </Col>
                            <Col>
                                <h1 className="ml-2 mb-0">{t("Book Appointment")}</h1>
                            </Col>
                        </Row>
                        <Row className="head_container mt-2">
                            <Col span={24}>
                                <Steps current={isAppointmentSteps.steps} size="small">
                                    {steps.map((step) => (
                                        <Step key={step.title} title={step.title} />
                                    ))}
                                </Steps>
                            </Col>
                        </Row>
                        {steps[isAppointmentSteps.steps].content}
                        <Row className="mt-5 button_container  text-right">
                            <Col span={24} className="">
                                <Space>
                                    {isAppointmentSteps.steps !== 0 && (
                                        <Button
                                            style={{ margin: "0 8px" }}
                                            onClick={() =>
                                                setIsAppointmentSteps({
                                                    steps: isAppointmentSteps.steps - 1,
                                                    isSuccess: true,
                                                })
                                            }
                                            disabled={isAppointmentSteps.steps < 1}
                                        >
                                            {t("Previous")}
                                        </Button>
                                    )}
                                    {isAppointmentSteps.steps < steps.length - 1 && (
                                        <Button
                                            type="primary"
                                            onClick={() => onNexStep()}
                                            disabled={isNextButtonEnable}
                                        >
                                            {t("Next")}
                                        </Button>
                                    )}
                                    {isAppointmentSteps.steps === steps.length - 1 && (
                                        <Button type="primary" onClick={() => setIsPaymentOptionModal(true)}>
                                            {t("Confirm Booking")}
                                        </Button>
                                    )}
                                </Space>
                            </Col>
                        </Row>
                        <PaymentModal />
                    </>
                );
            }}
        </Observer>
    );
};

export default BookAppointment;

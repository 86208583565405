import { createContext, useContext } from "react";
import LoginStore from "views/auth-views/login/login.store";
import SignInStore from "views/auth-views/signIn/signIn.store";
import DoctorDashboardStore from "views/doctor-views/dashboard/doctorDashboard.store";
import DoctorStore from "views/doctor-views/doctor.store";
import PrescriptionStore from "views/doctor-views/prescribe-report/prescription.store";
import DoctorProfileStore from "views/doctor-views/profile/doctorProfile.store";
import DoctorViewAppointmentStore from "views/doctor-views/view-appointment/doctorViewAppointment.store";
import PatientDashboardStore from "views/patient-views/dashboard/patientDashboard.store";
import PatientStore from "views/patient-views/patientStore.store";
import PatientPrescriptionStore from "views/patient-views/prescription-list/prescription.store";
import PatientProfileStore from "views/patient-views/profile/patientProfile.store";
import VideoConferenceStore from "views/videoConference/videoConference.store";
import GlobalStore from "./globalStore";
import PoliciesStore from "views/polices/polices.store";

class MainStore {
    constructor() {
        this.globalStore = new GlobalStore(this);

        // General
        this.loginStore = new LoginStore(this);
        this.signInStore = new SignInStore(this);

        // Doctor Specific
        this.doctorProfileStore = new DoctorProfileStore(this);
        this.prescriptionStore = new PrescriptionStore(this);
        this.doctorStore = new DoctorStore(this);
        this.doctorViewAppointmentStore = new DoctorViewAppointmentStore(this);
        this.doctorDashboardStore = new DoctorDashboardStore(this);
        // Patient Specific
        this.patientProfileStore = new PatientProfileStore(this);
        this.patientStore = new PatientStore(this);
        this.patientPrescriptionStore = new PatientPrescriptionStore();
        this.patientDashboardStore = new PatientDashboardStore()

        this.videoConferenceStore = new VideoConferenceStore(this);
        this.policiesStore = new PoliciesStore(this);
    }

    resetMainStore = () => {
        this.globalStore = new GlobalStore(this);
        this.loginStore = new LoginStore(this);
        this.signInStore = new SignInStore(this);
        this.doctorProfileStore = new DoctorProfileStore(this);
        this.doctorStore = new DoctorStore(this);
        this.prescriptionStore = new PrescriptionStore(this);
        this.patientProfileStore = new PatientProfileStore(this);
        this.patientStore = new PatientStore(this);
        this.doctorViewAppointmentStore = new DoctorViewAppointmentStore(this);
        this.patientPrescriptionStore = new PatientPrescriptionStore();
        this.videoConferenceStore = new VideoConferenceStore(this);
        this.doctorDashboardStore = new DoctorDashboardStore(this);
        this.patientDashboardStore = new PatientDashboardStore()
        this.policiesStore = new PoliciesStore(this);
    };
}

export const mainStore = new MainStore();

const storeContext = createContext({});

export const StoreProvider = storeContext.Provider;

export const useMainStore = () => useContext(storeContext);

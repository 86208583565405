import Loading from "components/shared-components/Loading";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";


const TermsNConditions = () => {

    const mainStore = useMainStore();
    const navigate = useNavigate()


    useEffect(() => {
        mainStore.policiesStore.setIsLoading(true)
        mainStore.policiesStore.getPolicyData().finally(() => {
            mainStore.policiesStore.setIsLoading(false)
        })
    }, [])



    return (
        <Observer>
            {() => {

                const {policies, isLoading} = mainStore.policiesStore

                if(isLoading){
                    return <Loading />
                }

                return (
                    <div className="container">
                        <div className="pt-5 tnc">

                        <div className="cursor-pointer" onClick={() => navigate(-1)} >
                                <ArrowLeftOutlined style={{ fontSize: "20px" }} />
                            </div>

                            <h1 className="text-center">Terms and Conditions</h1>
                            <ol>
                                <li className="user" >
                                    <h2>Doctor</h2>
                                    <div dangerouslySetInnerHTML={{ __html: policies.doctor }}></div>
                                </li>
                                <li className="user" >
                                    <h2>Patient</h2>
                                    <div dangerouslySetInnerHTML={{ __html: policies.patient }}></div>
                                </li>
                            </ol>
                        </div>
                    </div>
                );
            }}
        </Observer>
    )


};

export default TermsNConditions;

import { Card, Col, Row } from "antd";
import { useMainStore } from "mobxStore/mainStore";
import { Link } from "react-router-dom";
import SignInForm from "../components/SignInForm";
import { useTranslation } from "react-i18next";
import NavLanguage from "components/layout-components/NavLanguage";

const SignIn = () => {
    const { signInStore } = useMainStore();
    const { t } = useTranslation();

    const backgroundStyle = {
        backgroundImage: 'url(/img/others/img-17.jpg)',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
    };

    return (
        <div style={backgroundStyle}>
            <div className="h-100">
                <div className="container d-flex flex-column justify-content-center h-100">
                    <Row justify="center">
                        <Col xs={20} sm={20} md={20} lg={7}>
                            <Card>
                                <div className="my-4">
                                    <Row justify="center">
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                        <div className="text-right">
                                            <NavLanguage />
                                        </div>
                                            <SignInForm />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="text-center">
                                    <p>
                                        {t("Already have an account?")} <Link to="/login">{t("Login")}</Link>
                                    </p>
                                </div>
                                <div className="text-center">
                                        <Link to="/tnc">{t("Terms and Conditions")}</Link>
                                        <span className="mx-2 text-muted" >
                                            <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                                        </span>
                                    </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SignIn;

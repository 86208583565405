import { getValidDataFromResponse, isValidArray } from "utils/utilities";

import { EDIT_PATIENT_PROFILE, GET_PATIENT_DETAILS } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/api";

class PatientProfileStore {
    isProfileUpdated = false;
    patientProfileData = [];
    isLoadingPatientProfileData = true;

    patientAllergies  = [];
    regularMedications = [];

    constructor({ globalStore }) {
        makeAutoObservable(this);
        this.globalStore = globalStore;
    }

    getPatientDetail = async (patientId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_PATIENT_DETAILS}/${patientId}` });

                const { data, error } = getValidDataFromResponse({ response });
                if (!error) {
                    this.setPatientProfileData(data);
                    return resolve({ success: true, data });
                }
                this.setPatientProfileData();
                return resolve({ success: true, data: [] });
            } catch (error) {
                this.setPatientProfileData();
                return resolve({ success: false, data: [] });
            }
        });
    };

    editPatientProfile = async (patientId, userData) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.setIsProfileUpdated(true);
                const { response } = await postRequest({
                    url: `${EDIT_PATIENT_PROFILE}/${patientId}`,
                    payload: userData,
                });
                const { data, error: editProfileError } = getValidDataFromResponse({ response, isRequired: true });
                if (!editProfileError && isValidArray(data)) {
                    this.setIsProfileUpdated();
                    this.getPatientDetail(patientId);
                    this.globalStore.getUserDetailsById(patientId);
                    return resolve({ success: true, data });
                }
                this.setIsProfileUpdated();
                return reject({ success: false, data: [] });
            } catch (error) {
                this.setIsProfileUpdated();
                return reject({ success: false, data: [] });
            }
        });
    };

    removeAllergy = (index) => {
        const updatedData =  this.patientAllergies.filter((_, i) => i !== index);
        runInAction(() => {
            this.patientAllergies = updatedData
        })
    };

    removeMedication = (index) => {
        const updatedData =  this.regularMedications.filter((_, i) => i !== index);
        runInAction(() => {
            this.regularMedications = updatedData
        })
    };

    updatePatientAllergies = (data = []) => {
        runInAction(() => {
            this.patientAllergies = data
        })
    }

    updatePatientMedications = (data = []) => {
        runInAction(() => {
            this.regularMedications = data
        })
    }

    setIsProfileUpdated = (value = false) => {
        runInAction(() => {
            this.isProfileUpdated = value;
        });
    };
    setIsLoadingPatientProfileData = (value = false) => {
        runInAction(() => {
            this.isLoadingPatientProfileData = value;
        });
    };
    setPatientProfileData = (data = []) => {
        runInAction(() => {
            this.patientProfileData = data;
        });
    };

    setPatientAllergies = (data = {}) => {
        runInAction(() => {
            this.patientAllergies = [...this.patientAllergies, data];
        });
    }

    setRegularMedications = (data = "") => {
        runInAction(() => {
            this.regularMedications = [...this.regularMedications, data];
        });
    }
}

export default PatientProfileStore;

import { CheckOutlined, DownOutlined, GlobalOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import lang from "assets/data/language.data.json";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { onDirectionChange, onLocaleChange } from "store/slices/themeSlice";

function getLanguageDetail(locale) {
    const data = lang.filter((elm) => elm.langId === locale);
    return data[0];
}

const SelectedLanguage = () => {
    const locale = useSelector((state) => state.theme.locale);

    const language = getLanguageDetail(locale);
    const { langName, icon } = language;

    return (
        <div className="d-flex align-items-center">
            <img style={{ maxWidth: "20px" }} src={`/img/flags/${icon}.png`} alt={langName} />
            <span className="font-weight-semibold ml-2">
                {langName} <DownOutlined className="font-size-xs" />
            </span>
        </div>
    );
};

const MenuItem = (props) => {
    const locale = useSelector((state) => state.theme.locale);

    const dispatch = useDispatch();

    const handleLocaleChange = (props) => {
        dispatch(onLocaleChange(props.langId));
		dispatch(onDirectionChange(props.direction))
        localStorage.setItem("direction", props.direction);
        localStorage.setItem("locale", props.langId);
        i18n.changeLanguage(props.langId);
    };

    return (
        <span
            className="d-flex justify-content-between align-items-center"
            onClick={() => handleLocaleChange(props)}
        >
            <div>
                <img style={{ maxWidth: "20px" }} src={`/img/flags/${props.icon}.png`} alt={props.langName} />
                <span className="font-weight-normal ml-2">{props.langName}</span>
            </div>
            {locale === props.langId ? <CheckOutlined className="text-success" /> : null}
        </span>
    );
};

const menu = (
    <Menu
        items={[
            {
                key: "En",
                label: <MenuItem icon="us" langName="English" langId="en" direction='ltr'/>,
            },
            // {
            //     key: "Ch",
            //     label: <MenuItem icon="cn" langName="Chinese" langId="zh" />,
            // },
            // {
            //     key: "Fr",
            //     label: <MenuItem icon="fr" langName="French" langId="fr" />,
            // },
            // {
            //     key: "Jp",
            //     label: <MenuItem icon="jp" langName="Janpanese" langId="ja" />,
            // },
			{
				key: 'Ar',
				label: <MenuItem icon="ar" langName="Arabic" langId="ar" direction='rtl'/>
			}
        ]}
    />
);

export const NavLanguage = ({ configDisplay }) => {
    return (
        <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
            {configDisplay ? (
                <a href="#/" className="text-gray" onClick={(e) => e.preventDefault()}>
                    <SelectedLanguage />
                </a>
            ) : (
                <div className="nav-item" style={{cursor: 'pointer'}} onMouseEnter={e => e.currentTarget.style.cursor = 'pointer'} onMouseLeave={e => e.currentTarget.style.cursor = 'default'}>
                    <img src="/img/translation.png" alt="translate" className="nav-icon mr-0" style={{width: '25px', height: '25px'}} />
                </div>
            )}
        </Dropdown>
    );
};

export default NavLanguage;

import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import style from "./phoneNumberInput.module.css"


const PhoneNumberInput = ({value, onChange}) => {

    return (
        <div className={style.phone_number_input}>
            {/* <label className={style.phone_number_label}> <span className={`${style.required_sign}`} >*</span> Phone Number</label> */}
            <PhoneInput
                country={'in'}
                onlyCountries={['ae', 'in', 'us']}
                value={value}
                containerClass={style.phone_input_container}
                inputClass={style.phone_input}
                buttonClass={style.phone_input_button}
                dropdownClass={style.phone_input_dropdown}
                onChange={phone => onChange(phone)}
    
            />
        </div>
    )
}

export default PhoneNumberInput
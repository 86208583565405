import { Form, Input, Modal } from "antd";
import { Observer } from "mobx-react";
import React, { Fragment } from "react";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMainStore } from "mobxStore/mainStore";
import { ERROR, sendNotification } from "utils/utilities";

const ForgotPasswordForm = () => {
    const { t } = useTranslation();
    const { loginStore } = useMainStore();
    const { sendResetPasswordLink } = loginStore;

    const [forgotPasswordForm] = Form.useForm();

    const [loading, setLoading] = React.useState(false);

    const handleFormSubmit = async () => {

        try {
            const formData = await forgotPasswordForm.validateFields();

            setLoading(true);

            const { success, message } = await sendResetPasswordLink(formData.email);

            if (success) {
                forgotPasswordForm.resetFields();
                loginStore.setForgotPasswordModal(false);
                sendNotification({ type: "success", message: t("You will receive an email with instructions, how to reset your password") });
                setLoading(false);

                return;
            }

            sendNotification({ message, type: ERROR });
            setLoading(false);


        } catch (error) {
            setLoading(false);
            if (error.hasOwnProperty("errorFields")) return;
        }
    };

    return (
        <>
            <Observer>
                {() => {
                    const { forgotPasswordModal, setForgotPasswordModal } = loginStore;
                    return (
                        <Fragment>
                            <Modal
                                maskClosable={false}
                                centered
                                title={t("Forgot Password")}
                                open={forgotPasswordModal}
                                onOk={handleFormSubmit}
                                onCancel={() => {
                                    forgotPasswordForm.resetFields();
                                    setForgotPasswordModal(false);
                                }}
                                confirmLoading={loading}
                                width={400}
                            >
                                <Form
                                    form={forgotPasswordForm}
                                    layout="vertical"
                                    name="forgot-password-form"
                                >
                                    <Form.Item
                                        className="m-0"
                                        name="email"
                                        label={t("Email")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please input your email"),
                                            },
                                            {
                                                type: "email",
                                                message: t("Please enter a validate email!"),
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<MailOutlined className="text-primary" />}
                                            placeholder={t("Enter Email")}
                                        />
                                    </Form.Item>
                                    <p className="text-gray mb-0 text-[12px]">
                                        {t("You will receive an email to reset your password")}.
                                    </p>
                                </Form>
                            </Modal>
                        </Fragment>
                    );
                }}
            </Observer>
        </>
    );
};

export default ForgotPasswordForm;

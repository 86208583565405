import { EyeOutlined } from "@ant-design/icons";
import { Button, Empty, Row, Space, Spin, Table } from "antd";
import Loading from "components/shared-components/Loading";
import { DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL, DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM, DATE_FORMAT_MMM_DD_YYYY_DAY, DATE_FORMAT_MMM_DD_YYYY_DAY_FULL, DATE_FORMAT_MMM_DD_YYYY_DAY_HH_MM } from "constants/DateConstant";
import { t } from "i18next";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLocalStorageItems } from "utils/utilities";

const PatientPrescriptionList = () => {
    const { patientPrescriptionStore } = useMainStore();
    const { user_id: patientId } = getLocalStorageItems({ get: true, key: "user_id" });
    useEffect(() => {
        const { patientPrescriptionList, getPatientPrescriptionList, setIsLoadingPatientPrescriptionList } =
            patientPrescriptionStore;
        getPatientPrescriptionList(patientId).finally(() => {
            setIsLoadingPatientPrescriptionList();
        });
    }, []);

    const columns = [
        {
            title: t("Appointment"),
            dataIndex: "appointment",
            key: "appointment",
            render: (_, { appointment }) => <p className="mb-0 text-capitalize text-gray">{appointment.unique_id}</p>,
        },
        {
            title: t("Doctor Name"),
            dataIndex: "doctor",
            key: "doctor",
            render: (_, { doctor }) => <p className="mb-0 text-capitalize text-gray">{doctor.name}</p>,
        },
        {
            title: t("Appointment Mode / Type"),
            dataIndex: "appointment",
            key: "appointment",
            render: (_, { appointment }) => (
                <p className="mb-0 text-capitalize text-gray">
                    {appointment.mode} /{appointment.type}
                </p>
            ),
        },
        {
            title: t("Appointment Time"),
            dataIndex: "appointment",
            key: "appointment",
            render: (_, { appointment }) => {
                const [startTime, endTime] = appointment.time.split("-");
                return (
                    <p className="mb-0 text-capitalize text-gray">
                        {moment.utc(startTime, "hh:mm A").local().format("hh:mm A") +
                            " - " +
                            moment.utc(endTime, "hh:mm A").local().format("hh:mm A")}
                    </p>
                );
            },
        },
        {
            title: t("Appointment Date"),
            dataIndex: "appointment",
            key: "appointment",
            render: (_, { appointment }) => (
                <p className="mb-0 text-capitalize text-gray">
                    {moment.utc(appointment.startTime).local()  .format(DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_FULL)}
                </p>
            ),
        },
        {
            title: t("Prescription Date"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_, { createdAt }) => (
                <p className="mb-0 text-capitalize text-gray">
                    {moment.utc(createdAt).local().format(DATE_FORMAT_DDDD_DD_MMMM_YYYY_DAY_HH_MM)}
                </p>
            ),
        },
        {
            title: t("View"),
            key: "action",
            render: (_, { prescription_id }) => (
                <Space size="middle">
                    <Button className="tabel_icon" type="primary" ghost>
                        <Link to="/patient/prescription-details" state={{ prescriptionId: prescription_id }}>
                            <EyeOutlined />
                        </Link>
                    </Button>
                </Space>
            ),
        },
    ];


    return (
        <>
            <Observer>
                {() => {
                    const { patientPrescriptionList, isLoadingPatientPrescriptionList } = patientPrescriptionStore;
                    {/* if (isLoadingPatientPrescriptionList) {
                        return <Loading />;
                    } */}

                    return (
                        <Spin spinning={isLoadingPatientPrescriptionList} className="appointments_wrapper">
                            <Row className="mb-2">
                                <h2 className="font-size-lg mb-0">{t("Prescription List")}</h2>
                            </Row>
                            {patientPrescriptionList ? (
                                <Table
                                    columns={columns}
                                    dataSource={patientPrescriptionList.map((item, ind) => ({
                                        ...item,
                                        key: ind,
                                    }))}
                                />
                            ) : (
                                <Empty description={t("You Don't Have any Prescription")} />
                            )}
                        </Spin>
                    );
                }}
            </Observer>
        </>
    );
};

export default PatientPrescriptionList;

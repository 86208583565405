import { EyeOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, List, Popconfirm, Row, Tag, Tooltip } from "antd";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, isValidArray } from "utils/utilities";

const UpcomingAppointment = ({ patientId }) => {
    const { patientStore } = useMainStore();
    const navigate = useNavigate();
    return (
        <Observer>
            {() => {
                const { listOfAppointmentUpcoming, isLoadingForAppointmentList } = patientStore;
                if (!isLoadingForAppointmentList && !isValidArray(listOfAppointmentUpcoming)) {
                    return <Empty />;
                }

                return listOfAppointmentUpcoming.map((data, ind) => {
                    return Object.entries(data)
                        .slice()
                        .reverse()
                        .map(([year, appointmentData]) => {
                            return (
                                <Fragment key={ind}>
                                    <p className="pl-4 font-weight-bold">{year}</p>
                                    <List
                                        itemLayout="vertical"
                                        size="large"
                                        pagination={false}
                                        dataSource={appointmentData}
                                        renderItem={(item) => {
                                            const {
                                                doctorDetails,
                                                startTime,
                                                paymentStatus,
                                                patient_id,
                                                appointment_id,
                                                appointmentMode,
                                                appointmentType,
                                                unique_id,
                                                is_completed,
                                                paymentMode,
                                                is_canceled,
                                            } = item;
                                            return (
                                                <>
                                                    <List.Item key={item.appointment_id}>
                                                        <Card className="position-relative">
                                                            {is_canceled && (
                                                                <Tag
                                                                    className="position-absolute cancel-tag rounded-0 text-uppercase"
                                                                    color="#FF6B72"
                                                                    size="large"
                                                                >
                                                                    Canceled
                                                                </Tag>
                                                            )}
                                                            <Row
                                                                align="center"
                                                                className="d-flex  justify-content-between"
                                                            >
                                                                <Col className="appointment_date px-3">
                                                                    <h1 className="mb-0">
                                                                        {moment.utc(startTime).local().format("DD")}
                                                                    </h1>
                                                                    <h5>
                                                                        {moment.utc(startTime).local().format("MMM")}
                                                                    </h5>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">{t("Time")}</h4>
                                                                    <p className="">
                                                                        {moment
                                                                            .utc(startTime)
                                                                            .local()
                                                                            .format("hh:mm A")}
                                                                    </p>
                                                                </Col>
                                                                <Col span={2} className="">
                                                                    <h4 className="mb-1">{t("Name")}</h4>
                                                                    <p className="">{doctorDetails.name}</p>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">{t("Appointment Mode")}</h4>
                                                                    <Tag
                                                                        color={
                                                                            appointmentMode == "online"
                                                                                ? "blue"
                                                                                : "cyan"
                                                                        }
                                                                    >
                                                                        {appointmentMode == "online"
                                                                            ? "Virtual"
                                                                            : "Physical"}
                                                                    </Tag>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">{t("Payment Mode")}</h4>
                                                                    <p className="">
                                                                        {capitalizeFirstLetter(paymentMode)}
                                                                    </p>
                                                                </Col>
                                                                <Col className="">
                                                                    <h4 className="mb-1">{t("Payment status")}</h4>
                                                                    <Tag
                                                                        color={`${
                                                                            paymentStatus == "paid" ||
                                                                            paymentStatus == "COMPLETED" ||
                                                                            paymentMode == "offline"
                                                                                ? "#6FCE3F"
                                                                                : paymentStatus == "pending"
                                                                                  ? "#FBBF45"
                                                                                  : "#FF6D6D"
                                                                        }`}
                                                                        key={ind}
                                                                    >
                                                                        {" "}
                                                                        {paymentStatus == "paid" ||
                                                                        paymentStatus == "COMPLETED"
                                                                            ? t("PAID")
                                                                            : paymentMode == "offline"
                                                                              ? t("PAY IN CLINIC")
                                                                              : t("PENDING")}
                                                                    </Tag>
                                                                </Col>
                                                                <Col className="d-flex align-items-center">
                                                                    <Tooltip placement="top" title={t("View Details")}>
                                                                        <Button className="" type="primary" ghost>
                                                                            <Link
                                                                                to="/patient/view-appointment"
                                                                                state={{
                                                                                    appointment_id,
                                                                                    patient_id,
                                                                                    name: doctorDetails.name,
                                                                                    type: "upcoming",
                                                                                }}
                                                                            >
                                                                                <EyeOutlined />
                                                                            </Link>
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                        placement="top"
                                                                        title={
                                                                            appointmentMode === "offline"
                                                                                ? ""
                                                                                : is_completed
                                                                                  ? ""
                                                                                  : paymentStatus === "pending" &&
                                                                                      appointmentMode === "online"
                                                                                    ? ""
                                                                                    : t("Join Consultation")
                                                                        }
                                                                        className="ml-2"
                                                                    >
                                                                        <Popconfirm
                                                                            title="Are you sure you want to join?"
                                                                            description="Open Popconfirm with Promise"
                                                                            placement="topRight"
                                                                            onConfirm={() => {
                                                                                navigate("/video-consultation", {
                                                                                    state: {
                                                                                        userType: 1,
                                                                                        appointmentId: appointment_id,
                                                                                        patientId,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            disabled={
                                                                                appointmentMode === "offline"
                                                                                    ? true
                                                                                    : is_completed
                                                                                      ? true
                                                                                      : paymentStatus === "pending" &&
                                                                                          appointmentMode === "online"
                                                                                        ? true
                                                                                        : is_canceled
                                                                                          ? true
                                                                                          : false
                                                                            }
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                            onOpenChange={() =>
                                                                                console.log("open change")
                                                                            }
                                                                        >
                                                                            <Button
                                                                                className="tabel_icon"
                                                                                type="primary"
                                                                                disabled={
                                                                                    appointmentMode === "offline"
                                                                                        ? true
                                                                                        : is_completed
                                                                                          ? true
                                                                                          : paymentStatus ===
                                                                                                  "pending" &&
                                                                                              appointmentMode ===
                                                                                                  "online"
                                                                                            ? true
                                                                                            : is_canceled
                                                                                              ? true
                                                                                              : false
                                                                                }
                                                                            >
                                                                                <VideoCameraOutlined />
                                                                            </Button>
                                                                        </Popconfirm>
                                                                    </Tooltip>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </List.Item>
                                                </>
                                            );
                                        }}
                                    />
                                </Fragment>
                            );
                        });
                });
            }}
        </Observer>
    );
};

export default UpcomingAppointment;

import { FileDoneOutlined, HomeOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Col, DatePicker, Empty, Form, Radio, Row, Skeleton, Space, Tag } from "antd";
import { Icon } from "components/util-components/Icon";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import moment from "moment";
import { Fragment } from "react";
import { FaSquare } from "react-icons/fa";
import { capitalizeFirstLetter, isValidArray, isValidObject } from "utils/utilities";

const slotsOptions = [
    {
        label: "Morning",
        value: "morningSlots",
    },
    {
        label: "Afternoon",
        value: "afterNoonSlots",
    },
    {
        label: "Evening",
        value: "eveningSlots",
        title: "eveningSlots",
    },
    {
        label: "Night",
        value: "nightSlots",
    },
];

const StepOne = ({ slotForm, doctorId }) => {
    const { patientStore } = useMainStore();

    const onChange = (value) => {
        const {
            getDoctorsAvailableSlots,
            setIsLoadingDoctorAvailableSlots,
            setIsSelectedDateByCalender,
            isSelectedDateByCalender,
        } = patientStore;

        setIsSelectedDateByCalender(value);
        setIsLoadingDoctorAvailableSlots(true);
        const selectedDate = { date: moment(value).format("DD-MM-YYYY") };
        getDoctorsAvailableSlots(doctorId, selectedDate).finally(() => setIsLoadingDoctorAvailableSlots(false));
    };

    const currentTime = moment();

    const getSlotOption = (value) => {
        const { setGetSlotsByTime } = patientStore;
        setGetSlotsByTime(value.target.value);
    };
    const disabledDate = (current) => {
        const currentDate = new Date();
        return (current && current.year() > currentDate.getFullYear()) || current < currentDate.setHours(0, 0, 0, 0);
    };

    return (
        <>
            <Observer>
                {() => {
                    const {
                        isAppointmentIdForActive,
                        doctorAvailableSlots,
                        isLoadingDoctorAvailableSlots,
                        isSelectedDateByCalender,
                        setIsAppointmentIdForActive,
                        getSlotsByTime,
                        isAppointmentFormData,
                        isLoadingDoctorsProfile,
                        doctorProfileDataForPatient,
                    } = patientStore;

                    const [{ doctorProfile, speciality, degrees, clinicInfo, fees }] = doctorProfileDataForPatient;
                    const [{ availableTimings, availability }] = doctorAvailableSlots;

                    return (
                        <Row className="mt-3" gutter={[16, 16]}>
                            <Col xxl={6} xl={6} lg={7} md={8} span={24}>
                                <Card loading={isLoadingDoctorsProfile} className="appointment_profile">
                                    <div className="">
                                        <div className="text-center pb-4">
                                            <img
                                                src={`${
                                                    doctorProfile?.profile_pic
                                                        ? doctorProfile?.profile_pic
                                                        : "/img/avatars/thumb-1.jpg"
                                                }`}
                                                width={100}
                                                height={100}
                                                className="rounded"
                                                alt=""
                                            />
                                            <h3 className="mt-3">{doctorProfile?.name}</h3>
                                            <h4 className="text-primary mb-0 text-capitalize">
                                                {degrees?.map(({ degree_name }) => {
                                                    return <>{degree_name.toUpperCase()} </>;
                                                })}
                                            </h4>
                                        </div>
                                        <hr />

                                        <div className="py-4">
                                            <p className="d-flex align-items-center">
                                                <Icon type={PhoneOutlined} className="text-primary font-size-md mr-3" />
                                                {doctorProfile?.mobile_no}
                                            </p>
                                            <p className="d-flex align-items-start">
                                                <Icon
                                                    type={HomeOutlined}
                                                    className="text-primary font-size-md mr-3 mt-1"
                                                />
                                                {capitalizeFirstLetter(clinicInfo?.city)}
                                            </p>
                                            <p className="d-flex align-items-center">
                                                <Icon type={UserOutlined} className="text-primary font-size-md mr-3" />
                                                {capitalizeFirstLetter(doctorProfile?.gender)}
                                            </p>
                                            <p className="d-flex align-items-center mb-0">
                                                <Icon
                                                    type={FileDoneOutlined}
                                                    className="text-primary font-size-md mr-3"
                                                />
                                                {`${doctorProfile?.experience} Year`}
                                            </p>
                                        </div>
                                        <hr />
                                        <h3 className="mt-3 mb-0 ">{t("Speciality")}</h3>
                                        {speciality?.map(({ speciality_name }) => {
                                            return (
                                                <Tag color="blue" className="mt-3 text-capitalize">
                                                    {speciality_name}
                                                </Tag>
                                            );
                                        })}
                                    </div>
                                </Card>
                            </Col>
                            <Col xxl={18} xl={18} lg={17} md={16} span={24}>
                                <Form name="slotForm" form={slotForm} layout="vertical">
                                    <Card
                                        bodyStyle={{
                                            maxHeight: "550px",
                                            overflowY: "scroll",
                                        }}
                                        className="slot_card"
                                    >
                                        <div className="d-flex justify-content-between">
                                            <h2>{isSelectedDateByCalender.format(" dddd, DD MMMM YYYY")}</h2>
                                            <div>
                                                <DatePicker
                                                    onChange={(e) => onChange(e)}
                                                    allowClear={false}
                                                    disabledDate={disabledDate}
                                                    defaultValue={isSelectedDateByCalender}
                                                    format={"DD-MM-YYYY"}
                                                />
                                            </div>
                                        </div>
                                        <>
                                            <div className="d-flex justify-content-between align-items-xl-center align-items-start my-3">
                                                <div className="d-xl-flex align-items-center">
                                                    <span className="d-flex align-items-center mr-3 mb-xl-0 mb-2">
                                                        <FaSquare size={20} color="#FAFAFA" className="border mr-2" />
                                                        <span>{t("Available")}</span>
                                                    </span>
                                                    <span className="d-flex align-items-center">
                                                        <FaSquare size={20} color="#9DAECF" className="border mr-2" />
                                                        <span>{t("Already Booked")}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            {isValidObject(doctorAvailableSlots) && isValidArray(availableTimings) ? (
                                                <>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("This field is required"),
                                                            },
                                                        ]}
                                                        name={"time"}
                                                        label={t("Select Time Slot")}
                                                    >
                                                        <Radio.Group
                                                            name="time"
                                                            onChange={(val) =>
                                                                setIsAppointmentIdForActive(val.target.id)
                                                            }
                                                        >
                                                            <Space wrap>
                                                                {availableTimings?.map(({ time, isBooked }, _ind) => {
                                                                    const [startTime, endTime] = time?.split("-");
                                                                    return !isLoadingDoctorAvailableSlots ? (
                                                                        <Fragment key={_ind}>
                                                                            <Radio
                                                                                className="book_appointment_radio"
                                                                                value={time}
                                                                                disabled={isBooked}
                                                                                id={_ind}
                                                                            >
                                                                                <Tag
                                                                                    color={
                                                                                        _ind ==
                                                                                            isAppointmentIdForActive &&
                                                                                        !isBooked
                                                                                            ? "processing"
                                                                                            : isBooked
                                                                                              ? "#9DAECF"
                                                                                              : ""
                                                                                    }
                                                                                    disabled={isBooked}
                                                                                    size={"large"}
                                                                                    className={`${
                                                                                        !isBooked
                                                                                            ? "cursor_pointer"
                                                                                            : ""
                                                                                    } px-4 py-1 w-100`}
                                                                                >
                                                                                    <span className="mr-2">
                                                                                        {moment
                                                                                            .utc(startTime, "hh:mm A")
                                                                                            .local()
                                                                                            .format("hh:mm A")}
                                                                                    </span>
                                                                                    <span>-</span>
                                                                                    <span className="ml-2">
                                                                                        {moment
                                                                                            .utc(endTime, "hh:mm A")
                                                                                            .local()
                                                                                            .format("hh:mm A")}
                                                                                    </span>
                                                                                </Tag>
                                                                            </Radio>
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Skeleton.Input
                                                                            className=" px-2 py-1 w-100"
                                                                            active={true}
                                                                            size={"small"}
                                                                        />
                                                                    );
                                                                })}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    {isValidArray(availability) && (
                                                        <Form.Item
                                                            className="mb-0"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("This field is required"),
                                                                },
                                                            ]}
                                                            name={"appointmentMode"}
                                                            label={t("Select Appointment Mode")}
                                                        >
                                                            <Radio.Group
                                                                options={availability.map((mode, ind) => {
                                                                    return {
                                                                        key: mode,
                                                                        label: mode === "online"
                                                                            ? t("Virtual")
                                                                            : mode === "offline"
                                                                                ? t("Physical")
                                                                                : t(capitalizeFirstLetter(mode)),
                                                                        value: mode,
                                                                    };
                                                                })}
                                                            ></Radio.Group>
                                                        </Form.Item>
                                                    )}
                                                </>
                                            ) : (
                                                <Empty description={t("No slots for this day cycle")} />
                                            )}
                                        </>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    );
                }}
            </Observer>
        </>
    );
};

export default StepOne;

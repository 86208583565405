import { getRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { GET_POLICY_DATA } = require("constants/ApiConstant");
const { runInAction, makeAutoObservable } = require("mobx");

class PoliciesStore {

    policies = { doctor: "", patient: "", privacyPolicy: "" };
    isLoading = true



    constructor() {
        makeAutoObservable(this);
    }

    getPolicyData = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: GET_POLICY_DATA });
                const { data, error } = getValidDataFromResponse({ response });
    
                if (!error && isValidArray(data)) {
                    const [{ doctor, patient, privacyPolicy }] = data
                    this.setPolicies({ doctor, patient, privacyPolicy });
                    return resolve({ success: true, data });
                }
            } catch (error) {
                console.log("error", error.message);
            }
        })
    }

    setPolicies = (data) => {
        runInAction(() => {
            this.policies = data;
        })
    }

    setIsLoading = (value) => {
        runInAction(() => {
            this.isLoading = value;
        })
    }
}

export default PoliciesStore
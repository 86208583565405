import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect } from "react";
import useFirstLogin from "utils/hooks/useFirstLogin";
import { getLocalStorageItems, isValidArray, setLocalStorageItems } from "utils/utilities";

const DiagnosisQuestions = () => {
    const { doctorProfileStore } = useMainStore();
    const [form] = Form.useForm();
    const isFirstLogin = useFirstLogin();

    useEffect(() => {
        const { getDiagnosisQuestionsByDoctor, setIsLoadingDiagnosisQuestions, isDiagnosisQuestions } =
            doctorProfileStore;

        const { user_id } = getLocalStorageItems({ get: true, key: "user_id" });
        getDiagnosisQuestionsByDoctor({ doctorId: user_id }).finally(() => {
            setIsLoadingDiagnosisQuestions(false);
        });
    }, []);

    const onFinish = async (values) => {
        const { addDiagnosisQuestionsBySpecialist, isDiagnosisQuestions, setIsUpdatingQuestions } = doctorProfileStore;
        const { user_id, first_login } = getLocalStorageItems({
            getAll: true,
            key: ["user_id", "step", "first_login"],
        });
        form.setFieldsValue(isDiagnosisQuestions);
        setIsUpdatingQuestions(true);
        addDiagnosisQuestionsBySpecialist({ doctorId: user_id, questionsData: values }).then(() => {
            setIsUpdatingQuestions();
            isFirstLogin(first_login, 4);
            if (first_login) {
                setLocalStorageItems({
                    set: true,
                    item: {
                        key: "first_login",
                        value: false,
                    },
                });
            }
        });
    };
    return (
        <>
            <Observer>
                {() => {
                    const {
                        getDiagnosisQuestionsByDoctor,
                        isLoadingDiagnosisQuestions,
                        isDiagnosisQuestions,
                        isUpdatingQuestions,
                    } = doctorProfileStore;

                    const [{ questions }] = isDiagnosisQuestions;

                    const questionArr = questions?.map((questionData) => {
                        const { ansType, question } = questionData;
                        return { ansType, question };
                    });

                    return !isLoadingDiagnosisQuestions ? (
                        <Form
                            name="dynamic_form_nest_item"
                            initialValues={{ questions: isValidArray(questions) ? [...questionArr] : [{}] }}
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                        >
                            <Row justify="start">
                                <Col span={24}>
                                    <Form.List name="questions">
                                        {(fields, { add, remove }) => (
                                            <>
                                                <Row gutter={[16, 16]}>
                                                    {fields.map(({ key, name, ...restField }, index) => (
                                                        <Fragment key={index}>
                                                            <Col span={13} xl={16} xxl={18}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "question"]}
                                                                    label={t("Question")}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: t("Please add question title"),
                                                                        },
                                                                    ]}
                                                                    labelCol={{ span: 24 }}
                                                                >
                                                                    <Input
                                                                        placeholder={t("Question title")}
                                                                        className="w-100"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={11} xl={8} xxl={6}>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, "ansType"]}
                                                                        label={t("Answer Type")}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: t("Please select answer type"),
                                                                            },
                                                                        ]}
                                                                        labelCol={{ span: 24 }}
                                                                    >
                                                                        <Radio.Group>
                                                                            <Radio value={"boolean"}>{t("Yes/no")}</Radio>
                                                                            <Radio value={"descriptive"}>
                                                                                {t("Descriptive")}
                                                                            </Radio>
                                                                        </Radio.Group>
                                                                    </Form.Item>

                                                                    <Button
                                                                        type=""
                                                                        danger
                                                                        onClick={() => remove(name)}
                                                                        size="small"
                                                                    >
                                                                        <DeleteOutlined style={{ color: "red" }} />
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            {/* {index !== 0 && ( */}
                                                            {/* <Col className="d-flex align-items-center">
                                                            </Col> */}
                                                            {/* )} */}
                                                        </Fragment>
                                                    ))}
                                                </Row>
                                                <Row justify="center mt-3">
                                                    <Col span={6}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                block
                                                                icon={<PlusOutlined />}
                                                            >
                                                                {t("Add New Questions")}
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button type="primary" loading={isUpdatingQuestions} htmlType="submit">
                                            {t("Save")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <Loading />
                    );
                }}
            </Observer>
        </>
    );
};
export default DiagnosisQuestions;

import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input } from 'antd'
import { Observer } from 'mobx-react'
import { useMainStore } from 'mobxStore/mainStore';
import React, { Fragment, useEffect, useRef } from 'react'

const PatientMedications = () => {

    const mainStore = useMainStore();
    const { patientProfileStore, globalStore } = mainStore;

    const medicationsRef = useRef(null);

    const [medication, setMedication] = React.useState("");

    useEffect(() => {
        const [{ regular_medications }] = patientProfileStore.patientProfileData
        patientProfileStore.updatePatientMedications(regular_medications)
    }, [patientProfileStore])

    const addMedication = () => {
        if (medication === "") return;
        patientProfileStore.setRegularMedications(medication);
        setMedication("");
        medicationsRef.current.focus();
    };

    return (
        <Observer>
            {() => {

                const { regularMedications, removeMedication } = patientProfileStore;


                return (
                    <Fragment>
                        <h4>Regular Medications</h4>
                        <Input.Group compact>
                            <Input
                                style={{
                                    width: "calc(100% - 5rem)",
                                }}
                                ref={medicationsRef}
                                onChange={(e) => setMedication(e.target.value)}
                                value={medication}
                                placeholder="Metformin 500mg twice daily"
                            />
                            <Button
                                style={{ width: "5rem" }}
                                onClick={addMedication}
                                type="default"
                            >
                                Add
                            </Button>
                        </Input.Group>
                        {regularMedications.map((allergy, index) => (
                            <div className="allergy_list" key={index}>
                                <div className="allergy">
                                    <div className="font-medium allergy_value">{allergy}</div>
                                    <div className="allergy_remove" >
                                        <CloseOutlined style={{ cursor: "pointer" }} onClick={() => removeMedication(index)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                )
            }}
        </Observer>
    )
}

export default PatientMedications
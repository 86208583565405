import {
    GET_ALL_DEGREES,
    GET_ALL_SPECIALITY,
    GET_EXPERIENCE_KEY_VALUE,
    GET_GENDER_KEY_VALUE,
    GET_NOTIFICATION,
    GET_USER_DETAIL_BY_ID,
    READ_NOTIFICATION,
    UPDATE_PROFILE_IMAGE,
    UPDATE_USER_PASSWORD,
} from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class GlobalStore {
    allSpecialities = [];
    allDegrees = [];

    experienceKeyValue = [];
    genderKeyValue = [];

    isLoadingUserData = false;
    loggedInUserDetails = [{}];

    isUpdatingProfileImage = false;
    isUpdatingUserPasswords = false;

    headerNavProfileDetail = {
        userName: "",
        userDegree: [],
        userProfile: "/img/avatars/vDoctor_default_profile.png",
    };

    jitsiAPI = {};

    constructor() {
        makeAutoObservable(this);
    }

    getAllSpecialities = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await getRequest({ url: GET_ALL_SPECIALITY });

                const { data, error: allSpecialityError } = getValidDataFromResponse({ response });

                if (!allSpecialityError) {
                    this.setAllSpecialities(data);
                    return resolve({ success: true, data });
                }
            } catch (error) {
                console.log("getAllSpecialities", error.message);
                this.setAllSpecialities();
                return resolve({ success: false, data: [] });
            }
        });
    };

    getAllDegrees = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await getRequest({ url: GET_ALL_DEGREES });

                const { data, error: allDEgreesError } = getValidDataFromResponse({ response });

                if (!allDEgreesError) {
                    this.setAllDegrees(data);
                    return resolve({ success: true, data });
                }
            } catch (error) {
                console.log("getAllDegrees", error.message);
                return resolve({ success: false, data: [] });
            }
        });
    };

    getExperienceKeyValue = async () => {
        try {
            const { response } = await getRequest({ url: GET_EXPERIENCE_KEY_VALUE });

            const { data, error } = getValidDataFromResponse({ response });

            if (!error) {
                return this.setExperienceKeyValue(data);
            }

            return this.setExperienceKeyValue();
        } catch (error) {
            console.log("getExperienceKeyValue", error.message);
            return this.setExperienceKeyValue();
        }
    };

    getGenderKeyValue = async () => {
        try {
            const { response } = await getRequest({ url: GET_GENDER_KEY_VALUE });

            const { data, error } = getValidDataFromResponse({ response });

            if (!error) {
                return this.setGenderKeyValue(data);
            }

            return this.setGenderKeyValue();
        } catch (error) {
            console.log("getExperienceKeyValue", error.message);
            return this.setGenderKeyValue();
        }
    };

    getUserDetailsById = async (userId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${GET_USER_DETAIL_BY_ID}/${userId}` });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setLoggedInUserDetails(data);
                    return resolve();
                }
                this.setLoggedInUserDetails();
                return resolve();
            } catch (error) {
                this.setLoggedInUserDetails();
                console.log("getUserDetailsById", error.message);
                resolve();
            }
        });
    };

    updateProfileImage = async ({ userId, formData }) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.setIsUpdatingProfileImage(true);

                const { response } = await patchRequest({
                    url: `${UPDATE_PROFILE_IMAGE}/${userId}`,
                    payload: formData,
                });

                const { data, error } = getValidDataFromResponse({ response });

                if (!error && isValidArray(data)) {
                    this.setIsUpdatingProfileImage();
                    return resolve({ success: true, data });
                }

                this.setIsUpdatingProfileImage();
                return resolve({ success: false, data: [] });
            } catch (error) {
                console.log("updateProfileImage", error.message);
                this.setIsUpdatingProfileImage();
                resolve({ success: false, data: [] });
            }
        });
    };

    updateUserPassword = ({ userId, body }) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.setIsUpdatingUserPasswords(true);

                const { response } = await patchRequest({ url: `${UPDATE_USER_PASSWORD}/${userId}`, payload: body });

                const { data, error } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!error && isValidArray(data)) {
                    this.setIsUpdatingUserPasswords();
                    return resolve({ success: true, data });
                }

                this.setIsUpdatingUserPasswords();
                return resolve({ success: false, data: [] });
            } catch (error) {
                console.log("updateUserPassword", error.message);
                this.setIsUpdatingUserPasswords();
                resolve({ success: false, data: [] });
            }
        });
    };

    setAllSpecialities = (data = []) => {
        runInAction(() => {
            this.allSpecialities = data;
        });
    };

    setAllDegrees = (data = []) => {
        runInAction(() => {
            this.allDegrees = data;
        });
    };

    setExperienceKeyValue = (data = []) => {
        runInAction(() => {
            this.experienceKeyValue = data;
        });
    };

    setGenderKeyValue = (data = []) => {
        runInAction(() => {
            this.genderKeyValue = data;
        });
    };

    setLoggedInUserDetails = (data = [{}]) => {
        runInAction(() => {
            this.loggedInUserDetails = data;
        });
    };

    setIsLoadingUserData = (value = false) => {
        runInAction(() => {
            this.isLoadingUserData = value;
        });
    };

    setIsUpdatingProfileImage = (value = false) => {
        runInAction(() => {
            this.isUpdatingProfileImage = value;
        });
    };

    setIsUpdatingUserPasswords = (value = false) => {
        runInAction(() => {
            this.isUpdatingUserPasswords = value;
        });
    };

    setHeaderNavProfileDetail = (
        data = {
            userName: "",
            userDegree: [],
            userProfile: "/img/avatars/thumb-1.jpg",
        },
    ) => {
        runInAction(() => {
            this.headerNavProfileDetail = data;
        });
    };

    setJitsiAPI = (data = {}) => {
        runInAction(() => {
            this.jitsiAPI = data;
        });
    };
    getNotifications = async (userId, userType) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({
                    url: `${GET_NOTIFICATION}/${userId}/${userType}`,
                });
                const { data, error } = getValidDataFromResponse({ response });
                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data: data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            } 
        });
    };
    readNotification = async (notificationId, userType) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({
                    url: `${READ_NOTIFICATION}/${userType}/${notificationId}`,
                });

                const { data, error } = getValidDataFromResponse({ response });
                if (!error && isValidArray(data)) {
                    return resolve({ success: true, data: data });
                }

                return resolve({ success: false, data: [] });
            } catch (error) {
                return resolve({ success: false, data: [] });
            } 
        });
    };
} 

export default GlobalStore;

// socketListener.js

import io from "socket.io-client";

let socket = null;

export const initializeSocket = (getNotifications, user_id, userType, onDataUpdate) => {
  if (!socket) {
    socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    const fetchAndUpdateNotifications = async () => {
      try {
        const response = await getNotifications(user_id, userType);
        onDataUpdate(response?.data || []);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    // Initial fetch
    fetchAndUpdateNotifications();

    socket.on("read_notification", async () => {
      await fetchAndUpdateNotifications();
    });

    socket.on("doctor_appointment", async () => {
      await fetchAndUpdateNotifications();
    });
  }

  return () => {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  };
};

export const getSocket = () => socket;
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Select, Tag } from "antd";
import { toJS } from "mobx";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import React, { Fragment, useEffect, useRef } from "react";

const PatientAllergies = () => {
    const mainStore = useMainStore();
    const { patientProfileStore } = mainStore;

    const allergiesRef = useRef(null);

    const [allergyValue, setAllergyValue] = React.useState({
        value: "",
        level: "high",
    });

    useEffect(() => {
        const [{ allergies }] = patientProfileStore.patientProfileData
        patientProfileStore.updatePatientAllergies(allergies)
    }, [patientProfileStore])

    const addAllergy = () => {
        if (allergyValue.value === "") return;
        setAllergyValue({ value: "", level: "high" });
        patientProfileStore.setPatientAllergies(allergyValue);
        allergiesRef.current.focus();
    };

    const getValidColor = (level) => {
        switch (level.toLowerCase()) {
            case "high":
                return "red";
            case "medium":
                return "yellow";
            case "low":
                return "#87d068";
            default:
                return "purple";
        }
    };

    return (
        <Observer>
            {() => {
                const { patientAllergies, removeAllergy } = patientProfileStore;
                return (
                    <Fragment>
                        <h4>Allergies</h4>
                        <Input.Group compact>
                            <Input
                                style={{
                                    width: "calc(100% - 200px)",
                                }}
                                onChange={(e) =>
                                    setAllergyValue((prev) => ({
                                        ...prev,
                                        value: e.target.value,
                                    }))
                                }
                                ref={allergiesRef}
                                value={allergyValue.value}
                                placeholder="New Allergy"
                            />
                            <Select
                                value={allergyValue.level}
                                style={{ width: "100px" }}
                                onChange={(value) => setAllergyValue((prev) => ({ ...prev, level: value }))}
                            >
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                            </Select>
                            <Button style={{ width: "100px" }} onClick={addAllergy} type="default">
                                Add
                            </Button>
                        </Input.Group>
                        {patientAllergies.map((allergy, index) => (
                            <div className="allergy_list" key={index}>
                                <div className="allergy">
                                    <div className="font-medium allergy_value">{allergy.value}</div>
                                    <div className="font-medium ml-2 allergy_level">
                                        <Tag color={getValidColor(allergy.level)}>{allergy.level}</Tag>
                                    </div>
                                    <div className="allergy_remove">
                                        <CloseOutlined
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeAllergy(index)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                );
            }}
        </Observer>
    );
};

export default PatientAllergies;

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Typography, Alert } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useMainStore } from "mobxStore/mainStore";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { ERROR, sendNotification } from "utils/utilities";
import { Observer } from "mobx-react";
import NavLanguage from "components/layout-components/NavLanguage";

const { Title, Text } = Typography;

const backgroundStyle = {
    backgroundImage: "url(/img/others/img-17.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
};

const ResetPasswordForm = () => {
    const [form] = Form.useForm();
    const { loginStore } = useMainStore();
    const navigate = useNavigate();
    const { t } = useTranslation();


    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);

    const authToken = searchParams.get('auth_token')

    const onFinish = async (values) => {

        try {
            setLoading(true)

            const newBody = {
                authToken,
                newPassword: values.newPassword
            }

            const { message, success } = await loginStore.resetPassword(newBody)

            if (!success) {
                sendNotification({ type: ERROR, message })
                setLoading(false)
                return
            }

            setLoading(false)
            sendNotification({ type: "success", message })
            navigate('/login')

        } catch (error) {
            setLoading(false)
        }
    };


    return (
        <Observer>
            {() => {
                if (!authToken) {
                    return <Navigate to="/login" />
                }
                return (
                    <div style={backgroundStyle}>
                        <div className="h-100">
                            <div className="container d-flex flex-column justify-content-center align-items-center h-100">
                                <Card style={{ width: 400 }}>
                                    <div className="text-right">
                                        <NavLanguage />
                                    </div>
                                    <Title level={2} className="text-center m-0">
                                        {t("Reset Password")}
                                    </Title>
                                    <Text type="secondary" className="d-block text-center">
                                        {t("Enter your new password below")}
                                    </Text>
                                    <Form form={form} className="mt-4" name="reset-password" onFinish={onFinish} layout="vertical">
                                        <Form.Item
                                            name="newPassword"
                                            label={t("New Password")}
                                            rules={[{ required: true, message: t("Please input your new password") }]}
                                            hasFeedback
                                        >
                                            <Input.Password
                                                placeholder={t("Enter new password")}
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirmNewPassword"
                                            label={t("Confirm New Password")}
                                            dependencies={["newPassword"]}
                                            hasFeedback
                                            rules={[
                                                { required: true, message: t("Please confirm your new password") },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue("newPassword") === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error(t("Both password must be the same")));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder={t("Confirm new password")} />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={loading} block>
                                                {t("Reset Password")}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <Button className="w-100 p-0" type="link" onClick={() => navigate("/login", { replace: true })} >{t("Back to login")}</Button>
                                    {/* {success && (
                            <Alert
                                message="Success"
                                description="Your password has been reset successfully!"
                                type="success"
                                showIcon
                            />
                        )} */}
                                </Card>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    )

};

export default ResetPasswordForm;

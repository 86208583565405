import { Button, Card, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react'
import { LockOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import { useMainStore } from 'mobxStore/mainStore';
import { ERROR, sendNotification } from 'utils/utilities';

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
    backgroundSize: "cover",
    height: "100vh",
};

const VerifyAccount = () => {

    const { t } = useTranslation();
    const mainStore = useMainStore();
    const navigate = useNavigate();

    const { loginStore } = mainStore;


    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false);

    const authToken = searchParams.get('token')

    const verifyUser = async (data) => {

        setLoading(true)

        try {

            const newBody = {
                authToken,
                ...data

            }

            const { success, message } = await loginStore.verifyUser(newBody)

            if (!success) {
                sendNotification({ type: ERROR, message })
                setLoading(false)
                return
            }

            setLoading(false)
            sendNotification({ type: "success", message })
            navigate('/login')

        } catch (error) {
            setLoading(false)
        }

    }

  return (
    <Observer
    >
        {() => {
            return (
              <div style={backgroundStyle}>
                      <div className="h-100">
                          <div className="container d-flex flex-column justify-content-center h-100">
                              <Row justify="center">
                                  <Col xs={20} sm={20} md={20} lg={7}>
                                      <Card>
                                          <div className="mt-4">
                                              <Row justify="center">
                                                  <Col xs={24} sm={24} md={20} lg={20}>
                                                      <h2 className='text-center' >Verify Your Account</h2>
          
                                                      <p style={{ textAlign: "center", fontSize: "13px" }} >Please enter your password to verify your account.</p>
                                                      <Form
                                                          layout="vertical"
                                                          name="verify-user-form"
                                                          className="verify-user-form"
                                                          initialValues={{ remember: true }}
                                                          onFinish={verifyUser}
                                                      >
                                                          <Form.Item
                                                              name="password"
                                                              rules={[{ required: true, message: 'Please input your Password!' }]}
                                                          >
                                                              <Input.Password
                                                                  prefix={<LockOutlined className="site-form-item-icon" />}
                                                                  type="password"
                                                                  placeholder="Password"
                                                              />
                                                          </Form.Item>
                                                          <Form.Item>
                                                              <Button type="primary" htmlType="submit"className='w-100'  >
                                                                  Verify
                                                              </Button>
                                                          </Form.Item>
                                                      </Form>
                                                      
                                                  </Col>
                                              </Row>
                                          </div>
                                      </Card>
                                  </Col>
                              </Row>
                          </div>
                      </div>
                  </div>
            )
        }}
    </Observer>
  )

}

export default VerifyAccount
import { GET_DOCTOR_AND_PATIENTS, SEND_OTP, SIGN_IN_USER, VERIFY_OTP } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class SignInStore {
    userTypes = [];
    isCreatingNewUser = false;
    OTPField = false;

    constructor() {
        makeAutoObservable(this);
    }

    getUserTypes = async () => {
        try {
            const { response, error } = await getRequest({ url: GET_DOCTOR_AND_PATIENTS });
            if (!error && Array.isArray(response.data)) {
                const { data } = response;
                this.setUserTypes(data);
            }
        } catch (error) {
            console.log("error", error.message);
        }
    };

    createNewUser = async (formData) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.setIsCreatingNewUser(true);
                const { response, error } = await postRequest({ url: SIGN_IN_USER, payload: formData });
                const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!responseError && isValidArray(data)) {
                    this.setIsCreatingNewUser();
                    return resolve({ success: true, data: data });
                }
                this.setIsCreatingNewUser();
                return reject({ success: true, data: data });
            } catch (error) {
                this.setIsCreatingNewUser();
                return reject({ success: false, data: [] });
            }
        });
    };

    sendOTP = async (formData) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest({ url: SEND_OTP, payload: formData });
                const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!responseError && isValidArray(data)) {
                    this.setOTPField(true);
                    return resolve({ success: true, data: data });
                }
                return reject({ success: true, data: data });

            } catch (error) {
                return reject({ success: false, data: [] });
            }
        });
    };

    verifyOTP = async (formData) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response, error } = await postRequest({ url: VERIFY_OTP, payload: formData });
                const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true, isWarning: true });

                if (!responseError && isValidArray(data)) {
                    // this.setOTPField();
                    return resolve({ success: true, data: data });
                }
                // this.setOTPField();
                return reject({ success: true, data: data });
            } catch (error) {
                // this.setOTPField();
                return reject({ success: false, data: [] });
            }
        });
    };

    setUserTypes = (data = []) => {
        runInAction(() => {
            this.userTypes = data;
        });
    };

    setIsCreatingNewUser = (value = false) => {
        runInAction(() => {
            this.isCreatingNewUser = value;
        });
    };

    setOTPField = (value = false) => {
        runInAction(() => {
            this.OTPField = value;
        });
    };
}

export default SignInStore;

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import GoBack from "components/shared-components/GoBack";
import Loading from "components/shared-components/Loading";
import { t } from "i18next";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const PrescribeReport = () => {
    const [addPrescriptionForm] = Form.useForm();
    const navigate = useNavigate();
    const { prescriptionStore } = useMainStore();
    const { state } = useLocation();

    useEffect(() => {
        const { getPrescriptionByAppointmentId, setIsLoadingPrescription, setPrescriptionData } = prescriptionStore;
        setIsLoadingPrescription(true);
        getPrescriptionByAppointmentId(state.appointmentId)
            .then((res) => {})
            .finally(() => setIsLoadingPrescription());

        return () => setPrescriptionData();
    }, []);

    const onFinish = () => {
        const { addPrescription, setIsUpdatingPrescription, getPrescriptionByAppointmentId } = prescriptionStore;

        addPrescriptionForm
            .validateFields()
            .then(async (values) => {
                setIsUpdatingPrescription(true);
                await addPrescription({ formData: values, userData: state })
                    .then((res) => {
                        setIsUpdatingPrescription();

                        navigate(`/doctor/view-appointment`, {
                            state: {
                                appointmentId: state.appointmentId,
                                patientId: state.patientId,
                                openModal: false,
                            },
                            replace: true,
                        });
                    })
                    .catch((err) => console.log(err.message))
                    .finally(() => {
                        setIsUpdatingPrescription();
                    });
            })
            .catch((err) => {
                console.log("err", err.message);
            });
    };

    return (
        <Observer>
            {() => {
                const { prescriptionData, isUpdatingPrescription, isLoadingPrescription } = prescriptionStore;

                const { medications = [], testReports = [], notes = "" } = prescriptionData;

                const initialMedicationData = medications.map(
                    ({
                        medicineType = "",
                        medicineName = "",
                        medicineStrength = "",
                        medicineDose = "",
                        intakeDuration = "",
                        toBeTaken = "",
                        medicineIntakeTime = [],
                        importantNote = "",
                    }) => ({
                        medicineType,
                        medicineName,
                        medicineStrength: medicineStrength.split(" ")[0],
                        strengthUnit: medicineStrength.split(" ")[1],
                        medicineDose: medicineDose.split(" ")[0],
                        doseUnit: medicineDose.split(" ")[1],
                        intakeDuration: intakeDuration.split(" ")[0],
                        intakeUnit: intakeDuration.split(" ")[1],
                        toBeTaken,
                        medicineIntakeTime,
                        importantNote,
                    }),
                );

                const initialTestReportsData = testReports.map((report) => ({ report }));

                addPrescriptionForm.setFieldsValue({
                    medications: initialMedicationData,
                    testReports: initialTestReportsData,
                    notes: notes
                });

                return (
                    <>
                        <div className="d-flex justify-content-between report_heading mb-3 align-items-center">
                            <div className="d-flex">
                                <Space>
                                    <GoBack />
                                    <h2 className="mb-0">{`${t("Prescription for")} ${state.name}`}</h2>
                                </Space>
                            </div>
                            <Space>
                                <Button type="primary" loading={isUpdatingPrescription} onClick={onFinish}>
                                    {t("Save")}
                                </Button>
                            </Space>
                        </div>
                        {!isLoadingPrescription ? (
                            <Row>
                                <Col span={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24}>
                                            <Form
                                                name="add_prescription_form"
                                                form={addPrescriptionForm}
                                                autoComplete="off"
                                                layout="vertical"
                                            >
                                                <Row gutter={[16, 16]}>
                                                    <Col span={18}>
                                                        <Form.List name="medications">
                                                            {(fields, { add, remove }) => (
                                                                <>
                                                                    {fields.map(
                                                                        ({ key, name, ...restField }, index) => (
                                                                            <Card
                                                                                key={key}
                                                                                title={`${t("Medicine")} ${index + 1}`}
                                                                            >
                                                                                <Row gutter={[16, 16]}>
                                                                                    <Col span={18} xl={20} xxl={20}>
                                                                                        <Row gutter={[16, 16]}>
                                                                                            {/* medicineType */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    {...restField}
                                                                                                    name={[
                                                                                                        name,
                                                                                                        "medicineType",
                                                                                                    ]}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message:
                                                                                                                t("Missing medicine type"),
                                                                                                        },
                                                                                                    ]}
                                                                                                    label={t("Medicine Type")}
                                                                                                >
                                                                                                    <Select
                                                                                                        placeholder={t("Select Medicine Type")}
                                                                                                        options={[
                                                                                                            {
                                                                                                                value: "liquid",
                                                                                                                label: t("Liquid"),
                                                                                                            },
                                                                                                            {
                                                                                                                value: "tablet",
                                                                                                                label: t("Tablet"),
                                                                                                            },
                                                                                                            {
                                                                                                                value: "injections",
                                                                                                                label: t("Injections"),
                                                                                                            },
                                                                                                            {
                                                                                                                value: "drops",
                                                                                                                label: t("Drops"),
                                                                                                            },
                                                                                                        ]}
                                                                                                    />
                                                                                                </Form.Item>
                                                                                            </Col>

                                                                                            {/* Medicine Name */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    {...restField}
                                                                                                    name={[
                                                                                                        name,
                                                                                                        "medicineName",
                                                                                                    ]}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message:
                                                                                                                t("Missing medicine name"),
                                                                                                        },
                                                                                                    ]}
                                                                                                    label={t("Medicine Name")}
                                                                                                >
                                                                                                    <Input placeholder={t("Medicine Name")} />
                                                                                                </Form.Item>
                                                                                            </Col>

                                                                                            {/* medicineStrength */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    label={t("Strength")}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message: "",
                                                                                                        },
                                                                                                    ]}
                                                                                                >
                                                                                                    <Input.Group
                                                                                                        compact
                                                                                                    >
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[
                                                                                                                name,
                                                                                                                "medicineStrength",
                                                                                                            ]}
                                                                                                            noStyle
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message:
                                                                                                                        t("Missing medicine power"),
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <InputNumber
                                                                                                                type="number"
                                                                                                                controls={
                                                                                                                    false
                                                                                                                }
                                                                                                                placeholder={t("500mg")}
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                }}
                                                                                                            />
                                                                                                        </Form.Item>
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[
                                                                                                                name,
                                                                                                                "strengthUnit",
                                                                                                            ]}
                                                                                                            noStyle
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message:
                                                                                                                        "",
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Select
                                                                                                                placeholder={t("Select Unit")}
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                }}
                                                                                                            >
                                                                                                                <Select.Option value="mg">
                                                                                                                    {t("mg")}
                                                                                                                </Select.Option>
                                                                                                                <Select.Option value="mL">
                                                                                                                    {t("mL")}
                                                                                                                </Select.Option>
                                                                                                                <Select.Option value="mcg">
                                                                                                                    {t("mcg")}
                                                                                                                </Select.Option>
                                                                                                            </Select>
                                                                                                        </Form.Item>
                                                                                                    </Input.Group>
                                                                                                </Form.Item>
                                                                                            </Col>

                                                                                            {/* Medicine Dose */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                        },
                                                                                                    ]}
                                                                                                    label={t("Medicine Dose")}
                                                                                                >
                                                                                                    <Input.Group
                                                                                                        compact
                                                                                                        className="d-flex"
                                                                                                    >
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[
                                                                                                                name,
                                                                                                                "medicineDose",
                                                                                                            ]}
                                                                                                            noStyle
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message:
                                                                                                                        t("This field is required"),
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Select
                                                                                                                placeholder={t("Select Dose")}
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                }}
                                                                                                            >
                                                                                                                {Array(
                                                                                                                    10,
                                                                                                                )
                                                                                                                    .fill()
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            _,
                                                                                                                            index,
                                                                                                                        ) => (
                                                                                                                            <Select.Option
                                                                                                                                key={
                                                                                                                                    index
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    index +
                                                                                                                                    1
                                                                                                                                }
                                                                                                                            >
                                                                                                                                {index +
                                                                                                                                    1}
                                                                                                                            </Select.Option>
                                                                                                                        ),
                                                                                                                    )}
                                                                                                            </Select>
                                                                                                        </Form.Item>
                                                                                                        <Form.Item
                                                                                                            {...restField}
                                                                                                            name={[
                                                                                                                name,
                                                                                                                "doseUnit",
                                                                                                            ]}
                                                                                                            noStyle
                                                                                                            rules={[
                                                                                                                {
                                                                                                                    required: true,
                                                                                                                    message:
                                                                                                                        "",
                                                                                                                },
                                                                                                            ]}
                                                                                                        >
                                                                                                            <Select
                                                                                                                placeholder={t("Select Unit")}
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                }}
                                                                                                            >
                                                                                                                <Select.Option value="tablet">
                                                                                                                    {t("Tablet")}
                                                                                                                </Select.Option>
                                                                                                                <Select.Option value="mL">
                                                                                                                    {t("mL")}
                                                                                                                </Select.Option>
                                                                                                                <Select.Option value="drops">
                                                                                                                    {t("Drops")}
                                                                                                                </Select.Option>
                                                                                                            </Select>
                                                                                                        </Form.Item>
                                                                                                    </Input.Group>
                                                                                                </Form.Item>
                                                                                            </Col>

                                                                                            {/* Intake Duration */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <div className="medicine_duration">
                                                                                                    <Form.Item
                                                                                                        label={t("Intake Duration")}
                                                                                                        rules={[
                                                                                                            {
                                                                                                                required: true,
                                                                                                            },
                                                                                                        ]}
                                                                                                    >
                                                                                                        <Input.Group
                                                                                                            compact
                                                                                                            className="d-flex"
                                                                                                        >
                                                                                                            <Form.Item
                                                                                                                {...restField}
                                                                                                                name={[
                                                                                                                    name,
                                                                                                                    "intakeDuration",
                                                                                                                ]}
                                                                                                                noStyle
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        required: true,
                                                                                                                        message:
                                                                                                                            t("This field is required"),
                                                                                                                    },
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Select
                                                                                                                    placeholder={t("Select Duration")}
                                                                                                                    style={{
                                                                                                                        width: "50%",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {Array(
                                                                                                                        10,
                                                                                                                    )
                                                                                                                        .fill()
                                                                                                                        .map(
                                                                                                                            (
                                                                                                                                _,
                                                                                                                                index,
                                                                                                                            ) => (
                                                                                                                                <Select.Option
                                                                                                                                    key={
                                                                                                                                        index
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        index +
                                                                                                                                        1
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    {index +
                                                                                                                                        1}
                                                                                                                                </Select.Option>
                                                                                                                            ),
                                                                                                                        )}
                                                                                                                </Select>
                                                                                                            </Form.Item>
                                                                                                            <Form.Item
                                                                                                                {...restField}
                                                                                                                name={[
                                                                                                                    name,
                                                                                                                    "intakeUnit",
                                                                                                                ]}
                                                                                                                noStyle
                                                                                                                rules={[
                                                                                                                    {
                                                                                                                        required: true,
                                                                                                                        message:
                                                                                                                            "",
                                                                                                                    },
                                                                                                                ]}
                                                                                                            >
                                                                                                                <Select
                                                                                                                    placeholder={t("Select Length")}
                                                                                                                    style={{
                                                                                                                        width: "50%",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Select.Option value="day">
                                                                                                                        {t("Day")}
                                                                                                                    </Select.Option>
                                                                                                                    <Select.Option value="week">
                                                                                                                        {t("Week")}
                                                                                                                    </Select.Option>
                                                                                                                    <Select.Option value="month">
                                                                                                                        {t("Month")}
                                                                                                                    </Select.Option>
                                                                                                                </Select>
                                                                                                            </Form.Item>
                                                                                                        </Input.Group>
                                                                                                    </Form.Item>
                                                                                                </div>
                                                                                            </Col>

                                                                                            {/* To be Taken */}
                                                                                            <Col
                                                                                                span={12}
                                                                                                xl={12}
                                                                                                xxl={8}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    {...restField}
                                                                                                    name={[
                                                                                                        name,
                                                                                                        "toBeTaken",
                                                                                                    ]}
                                                                                                    size="small"
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message:
                                                                                                                t("Missing medicine toBeTaken"),
                                                                                                        },
                                                                                                    ]}
                                                                                                    label={t("To be Taken")}
                                                                                                >
                                                                                                    <Radio.Group
                                                                                                        options={[
                                                                                                            {
                                                                                                                label: t("Before Food"),
                                                                                                                value: "before_food",
                                                                                                            },
                                                                                                            {
                                                                                                                label: t("After Food"),
                                                                                                                value: "after_food",
                                                                                                            },
                                                                                                        ]}
                                                                                                        optionType="button"
                                                                                                        buttonStyle="solid"
                                                                                                    />
                                                                                                </Form.Item>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>

                                                                                    {/* medicineIntakeTime */}
                                                                                    <Col span={6} xl={4} xxl={4}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[
                                                                                                name,
                                                                                                "medicineIntakeTime",
                                                                                            ]}
                                                                                            rules={[
                                                                                                {
                                                                                                    required: true,
                                                                                                    message:
                                                                                                        t("Missing medicine dose"),
                                                                                                },
                                                                                            ]}
                                                                                            label={t("Medicine Time")}
                                                                                        >
                                                                                            <Checkbox.Group
                                                                                                className=" d-flex flex-column"
                                                                                                options={[
                                                                                                    {
                                                                                                        label: t("Morning"),
                                                                                                        value: "morning",
                                                                                                    },
                                                                                                    {
                                                                                                        label: t("Noon"),
                                                                                                        value: "noon",
                                                                                                    },
                                                                                                    {
                                                                                                        label: t("Evening"),
                                                                                                        value: "evening",
                                                                                                    },
                                                                                                    {
                                                                                                        label: t("Night"),
                                                                                                        value: "night",
                                                                                                    },
                                                                                                ]}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                </Row>

                                                                                {/* importantNote */}
                                                                                <Row align="middle">
                                                                                    <Col span={20}>
                                                                                        <Form.Item
                                                                                            {...restField}
                                                                                            name={[
                                                                                                name,
                                                                                                "importantNote",
                                                                                            ]}
                                                                                            size="small"
                                                                                            label={t("Important Note (If have)")}
                                                                                        >
                                                                                            <TextArea
                                                                                                placeholder={t("Any important note about this medicine")}
                                                                                                autoSize={{
                                                                                                    minRows: 2,
                                                                                                    maxRows: 6,
                                                                                                }}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>

                                                                                    {index !== 0 && (
                                                                                        <Col
                                                                                            span={4}
                                                                                            className="d-flex flex-column-reverse align-items-end"
                                                                                        >
                                                                                            <Button
                                                                                                onClick={() =>
                                                                                                    remove(name)
                                                                                                }
                                                                                                danger
                                                                                            >
                                                                                                {t("Remove")}
                                                                                            </Button>
                                                                                        </Col>
                                                                                    )}
                                                                                </Row>
                                                                            </Card>
                                                                        ),
                                                                    )}
                                                                    <Row justify="end">
                                                                        <Col xl={3}>
                                                                            <Form.Item>
                                                                                <Button
                                                                                    type="primary"
                                                                                    onClick={() => add()}
                                                                                    block
                                                                                    icon={<PlusOutlined />}
                                                                                >
                                                                                    {t("Add")}
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </Col>

                                                    {/* testReports */}
                                                    <Col span={6}>
                                                    <Form.Item label={t("Prescription Notes")} name="notes">
                                                            <Input.TextArea
                                                                placeholder={t("Add notes here")}
                                                                rows={3}
                                                                className="w-100"
                                                            />
                                                        </Form.Item>
                                                        <Card title={t("Test Reports")} className="report_wrapper">
                                                            <Form.List name="testReports">
                                                                {(fields, { add, remove }) => (
                                                                    <Row justify="center" align="middle">
                                                                        {fields.map(
                                                                            ({ key, name, ...restField }, index) => (
                                                                                <Fragment key={key}>
                                                                                    <Col lg={18} xl={19} xxl={20}>
                                                                                        <Form.Item
                                                                                            placeholder="Ex- CBC RBC"
                                                                                            className="mb-3"
                                                                                            {...restField}
                                                                                            name={[name, "report"]}
                                                                                            size="small"
                                                                                        >
                                                                                            <Input
                                                                                                placeholder={`${t("Test Report")} ${
                                                                                                    index + 1
                                                                                                }`}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg={6}
                                                                                        xl={5}
                                                                                        xxl={4}
                                                                                        className="d-flex flex-column-reverse align-items-end"
                                                                                    >
                                                                                        {index !== 0 && (
                                                                                            <DeleteOutlined
                                                                                                className="report_icon mb-3"
                                                                                                onClick={() =>
                                                                                                    remove(name)
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </Col>
                                                                                </Fragment>
                                                                            ),
                                                                        )}
                                                                        <Col span={24}>
                                                                            <Form.Item>
                                                                                <Button
                                                                                    type="primary"
                                                                                    onClick={() => add()}
                                                                                    block
                                                                                    icon={<PlusOutlined />}
                                                                                >
                                                                                    {t("Add Reports")}
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            </Form.List>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                {/* <Form.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Submit
                                                    </Button>
                                                </Form.Item> */}
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ) : (
                            <Loading />
                        )}
                    </>
                );
            }}
        </Observer>
    );
};

export default PrescribeReport;

import Loading from "components/shared-components/Loading";
import { Observer } from "mobx-react";
import { useMainStore } from "mobxStore/mainStore";
import React, { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const mainStore = useMainStore();
    const navigate = useNavigate()

    useEffect(() => {
        mainStore.policiesStore.setIsLoading(true);
        mainStore.policiesStore.getPolicyData().finally(() => {
            mainStore.policiesStore.setIsLoading(false);
        });
    }, []);

    return (
        <Observer>
            {() => {
                const { policies, isLoading } = mainStore.policiesStore;

                if (isLoading) {
                    return <Loading />;
                }

                return (
                    <div className="container">
                        <div className="pt-5 tnc">
                            <div className="cursor-pointer" onClick={() => navigate(-1)} >
                                <ArrowLeftOutlined style={{ fontSize: "20px" }} />
                            </div>

                            <div>
                                <h1 className="text-center">Privacy Policy</h1>
                                <div dangerouslySetInnerHTML={{ __html: policies.privacyPolicy }}></div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    );
};

export default PrivacyPolicy;
